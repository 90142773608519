import * as ActionTypes from '../constants';

const initialState = {
  isLoading: false,
  error: null,
  list: [],
  isFinished: false,
  total: 0
}

const orderlist = (state = initialState, action) => {
  switch(action.type) {
    case ActionTypes.ADD_ORDER_LIST: {
      return {
        ...state,
        list: [
          ...state.list,
          action.payload.order
        ],
        total: state.total + action.payload.price
      }
    }
    case ActionTypes.EDIT_ORDER_LIST: {
      const { orderId, updatedOrder } = action.payload;

      const updatedList = state.list.map(order =>
        order.id === orderId ? updatedOrder : order
      );

      const total = updatedList.reduce((acc, order) => acc + order.totalPrice, 0);

      return {
        ...state,
        list: updatedList,
        total
      };
    }
    case ActionTypes.DELETE_ORDER_LIST: {
      const { orderId } = action.payload;
    
      const updatedList = state.list.filter(order => order.id !== orderId);
    
      const total = updatedList.reduce((acc, order) => acc + order.totalPrice, 0);
    
      return {
        ...state,
        list: updatedList,
        total
      };
    }
    case ActionTypes.FINISH_ORDER: {
      return {
        ...initialState,
        isFinished: true
      }
    }
    case ActionTypes.SAVE_ORDER_LIST_SUCCESS: {
      return {
        ...state,
        list: [], 
        isFinished: true
      }
    }
    default: {
      return state;
    }
  }
}
export default orderlist;