import { Dialog, DialogContent, DialogTitle, Divider, Card, Box, Button, Grid } from '@mui/material';
import i18next from '../../i18n';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import OrderItemCard from './OrderItemCard';
import ConfirmationDelete from './ConfirmationDelete';

import { setProductSelectProcess } from '../../store/actions/category.action';
import { speakMP3Promise, speakMP3PromiseMedia , stopMP3 } from '../../utils/functions';
const apiKey = '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'


export default function ReviewOrder( props ) {
  const {
    handleFinishDialogOpen,
    reviewOpen,
    setReviewOpen,
    list,
    total,
    extrasDialogOpen,
    setExtrasDialogOpen,
    editEnable,
    setEditEnable,
    deleteEnable,
    setDeleteEnable,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    product,
    setProduct,
    order,
    setOrder,
    setCustomsArray,
    setExtrasArray,
    audio,
    open,
    setOpen
  } = props;

  const [totalExtras, setTotalExtras] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [deleteName, setDeleteName ] = useState('');
  
  const handleClickDelete = () => {
    setDeleteEnable(true)
    setOpen(true);
  };

  const mediaSourceRef = useRef(null);

  const startAudioStreaming = async () => {
    // Añade un listener para inicializar el SourceBuffer cuando se abra el MediaSource
    mediaSourceRef.current = new MediaSource();
    mediaSourceRef.current.addEventListener('sourceopen', () => handleSourceOpen());


    try {
      await speakMP3PromiseMedia(audio, mediaSourceRef.current);
    } catch (error) {
      console.error('Error during audio streaming:', error);
    }
  };


  const handleSourceOpen = async () => {

    const totalSource = isNaN((Math.round(totalProducts * 100) / 100).toFixed(2)) ? '0.00' : (Math.round(totalProducts * 100) / 100).toFixed(2);
    const [integerPart, cents] = totalSource.split('.');
  
    const mimeCodec = 'audio/mpeg'; // Codec para audio MP3
  
    try {
      const sourceBuffer = mediaSourceRef.current.addSourceBuffer(mimeCodec);
  
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          model: 'tts-1',
          voice: 'alloy',
          input: `Your total is ${integerPart} dollars and ${cents} cents.`,
          //stream: true, // Importante: habilitar streaming
        }),
      });
  
      if (!response.body) {
        throw new Error('No response body');
      }
  
      const reader = response.body.getReader();
  
      const processChunk = async (chunk) => {
        return new Promise((resolve, reject) => {
          const handleUpdateEnd = () => {
            sourceBuffer.removeEventListener('updateend', handleUpdateEnd);
            resolve();
          };
  
          sourceBuffer.addEventListener('updateend', handleUpdateEnd);
  
          // Asegurarse de que el buffer no esté ocupado antes de agregar
          if (!sourceBuffer.updating) {
            try {
              sourceBuffer.appendBuffer(chunk);
            } catch (error) {
              reject(error);
            }
          } else {
            // Rechaza la promesa si el buffer aún está ocupado (debería evitar esto)
            reject(new Error('SourceBuffer is still updating.'));
          }
        });
      };
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        await processChunk(value).catch((error) => {
          console.error('Error processing chunk:', error);
          throw error;
        });
      }
  
    } catch (error) {
      console.error('Error fetching or processing audio stream:', error);
    }
  };

  useEffect(() => {
    setTotalExtras(getTotalExtras());
    setTotalProducts(getTotalProducts());
  }, [list]);

  function getTotalExtras() {
    let total = 0;
    list.forEach(item => {
      total += item.extrasPrice;
    });
    return total;
  }
  function getTotalProducts() {
    let total = 0;
    list.forEach(item => {
      total += item.totalPrice;
    });
    return total;
  }
  
  const dispatch = useDispatch();

  function  handleClose() {
    setReviewOpen(false);
    try {  stopMP3(audio) } 
    catch (error) { console.error('Error Pausar reviewList:', error) }
    dispatch(setProductSelectProcess());
  }

  const reviewListMsg = async() => {
    try { 
      stopMP3(audio)
      await speakMP3Promise(audio, '/assets/audio/confirm-your-or.mp3')
     } 
    catch (error) { console.error('Error Pausar reviewList:', error) }
  }
  const reviewList2Msg = async() =>{
    try { 
      stopMP3(audio) 
      await speakMP3Promise(audio, '/assets/audio/something-wrong.mp3')
      await speakMP3Promise(audio, '/assets/audio/want-to-check-y.mp3')
    } 
    catch (error) {  console.error('Error Pausar reviewList2:', error) }
  } 
  const reviewList3Msg = async() =>{
    try { 
      stopMP3(audio) 
      await speakMP3Promise(audio, '/assets/audio/not-finished-g.mp3')
    } 
    catch (error) { console.error('Error Pausar reviewList3:', error) }
  } 
  let pointerMsg2 = null
  let pointerMsg3 = null

  function readReviewOrder() {
    if( list.length > 0 ){
      const total = isNaN((Math.round(totalProducts * 100) / 100).toFixed(2)) ? '0.00' : (Math.round(totalProducts * 100) / 100).toFixed(2);
      const [integerPart, cents] = total.split('.');
      reviewListMsg();
      pointerMsg2 = setTimeout(() => { reviewList2Msg() }, 2500);
      pointerMsg3 = setTimeout(() => { reviewList3Msg() }, 9000);
    }
  }


  useEffect(() => {
    if(reviewOpen) {
      
      startAudioStreaming();
      setEditEnable(true);
      setDeleteEnable(false);
      //readReviewOrder();
    }
    return () => {
      //clearTimeout(pointerMsg2);
      //clearTimeout(pointerMsg3);
    }
  }, [reviewOpen]);
  useEffect(() => {
    if(deleteId){
      const found = list.find((element) => element.id === deleteId);
      setDeleteName(found.name)
    }
  }, [deleteId]);

  const orderItemCardMethods = {
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    editEnable,
    deleteEnable,
    setDeleteEnable,
    extrasDialogOpen, 
    setExtrasDialogOpen,
    product,
    setProduct,
    order,
    setOrder,
    setReviewOpen,
    setCustomsArray,
    setExtrasArray,
    handleClickDelete
  }
 
  return (
    <>
    <Dialog
        open={reviewOpen} 
        onClose={handleClose}
        PaperProps={{
            sx: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%', 
                maxWidth: '666px',
                border: '1px solid #F56200', 
            }
        }}
    >
        <Box 
            sx={{
                width: '90%', 
                padding: 2, 
            }}
        >
            <div>
                <div className='flex flex-row justify-between items-center gap-22'>
                    <p 
                    className='font-review font-extrabold text-center text-4xl py-1 ml-4'
                    >
                        {i18next.t('Your Order Review')}
                    </p>
                </div>
            </div>

            <div className='flex justify-center items-center p-4'>
                <Box className='flex flex-col' sx={{ width: '100%' }}>
                    {list.length > 0 && list.map((item,index) => (
                        <OrderItemCard 
                        key={index}
                        index={index} 
                        data={item} 
                        orderItemCardMethods={orderItemCardMethods}
                        audio={audio}
                        />
                    ))} 
                </Box>
            </div>

            <div className="flex flex-col items-center">
                <p 
                className='font-review text-center text-[20px] py-1'
                style={{ marginRight: '12px'}}
                >
                    {i18next.t('Something Wrong?')}
                </p>
                <Box className='flex flex-row content-around  '>
                  <Button
                    onClick = {()=>{
                      if(!editEnable){
                        setEditEnable(!editEnable)
                        setDeleteEnable(!deleteEnable)
                      }
                    }}
                    color="primary"
                    component="label"
                    variant="outlined"
                    tabIndex={-1}
                    sx={{ 
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '150%',
                    minWidth: '170px',
                    marginRight: '2.5rem',
                    border: `1px solid`,
                    borderColor: editEnable ? '#F56200' : 'gray',
                    }}
                    startIcon={
                    <img 
                        src={editEnable ? `/assets/hands/a-white.svg`: `/assets/hands/a.svg`}
                        className={`h-16 w-16 rounded-sm ${editEnable?'bg-primary':'bg-gray-300'}`}
                        alt="hand"
                        style={{ 
                            objectFit: 'fill',
                            marginLeft: '0.25rem',
                        }}  
                    />
                    }
                    >
                    <div className={`font-review font-semibold ${editEnable?'text-orange-400':'text-black'}`}>Edit Item</div>
                  </Button>
                  <Button
                    onClick={()=>{
                      if(!deleteEnable){
                        setEditEnable(!editEnable)
                        setDeleteEnable(!deleteEnable)
                      }
                    }}
                    color="primary"
                    component="label"
                    variant="outlined"
                    tabIndex={-1}
                    sx={{ 
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    width: '150%',
                    minWidth: '170px',
                    marginLeft: '2.5rem',
                    border: `1px solid`,
                    borderColor: deleteEnable ? '#F56200' : 'gray', 
                    }} 
                    startIcon={
                    <img 
                        src={deleteEnable ? `/assets/hands/b-white.svg`: `/assets/hands/b.svg`}
                        className={`h-16 w-16 rounded-sm ${deleteEnable?'bg-primary':'bg-gray-300'}`}
                        alt="hand"
                        style={{ 
                            objectFit: 'fill',
                            marginLeft: '0.25rem',
                        }}  
                    />
                    }
                    >
                    <div className={`font-review font-semibold ${deleteEnable?'text-orange-400':'text-black'}`}>Delete Item</div>
                  </Button>
                </Box>
            </div>

            <Divider sx={{ my: 1, width: "100%", height: "1px", marginTop: "18px", backgroundColor: "#F56200" }} />

            <div className='flex flex-col items-end mr-4'>
                <div className='flex flex-row justify-end items-center'>
                    <p className='font-review font-bold text-2xl py-1 px-4'>{i18next.t('Extras: ')}</p> 
                    <p className='font-review text-2xl py-1'>$ { (Math.round(totalExtras * 100) / 100).toFixed(2)}</p>
                </div>
                <div className='flex flex-row justify-end items-center'>
                    
                    <p className='font-review font-bold text-2xl py-1 px-4'>{i18next.t('Your Total: ')}</p>
                    <p className='font-review text-2xl py-1'> ${ isNaN( (Math.round(totalProducts * 100) / 100).toFixed(2) ) ?  '0.00' : (Math.round(totalProducts * 100) / 100).toFixed(2) }
                    </p>
                </div>
            </div>

            <div className='flex justify-center items-center p-4'>
                
                <Box className='flex flex-row justify-between gap-[190px]'>
                    <Button
                        onClick = {handleClose}
                        variant="text"
                        tabIndex={-1}
                        sx={{ 
                        padding: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '96%',
                        }} 
                        startIcon={
                        <img 
                            src="/assets/hands/g.png"
                            className={`h-16 w-16 rounded-sm 'bg-white' `}
                            alt="hand"
                            style={{
                                padding: '0rem .1rem',
                            }}
                        />
                        }
                    >
                        <div className='font-review text-black font-semibold'>Back To Order</div>
                    </Button>

                    <Button
                        onClick={handleFinishDialogOpen}
                        color="primary"
                        variant="outlined"
                        tabIndex={-1}
                        sx={{ 
                        padding: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '96%',
                        }} 
                        startIcon={
                        <img 
                            src="/assets/hands/y-white.svg"
                            className={`h-[62px] w-[62px] rounded-sm bg-primary`}
                            alt="hand" 
                            style={{ 
                                objectFit: 'fill',
                                paddingTop: '.25rem',
                                paddingBottom: '.45rem',
                                marginLeft: '0.25rem',
                            }} 
                        />
                        }
                    >
                        <div className='font-review font-semibold'>Finish Order</div>
                    </Button>
                </Box>
            </div>
            <ConfirmationDelete
              open={open}
              setOpen={setOpen}
              deleteId={deleteId}
              deleteName={deleteName}
            />
        </Box>
    </Dialog>
    </>
  );
}