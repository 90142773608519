import { PRODUCT_SIZE } from "../../utils/enums";
import i18next from "i18next";
import { Divider, Box } from "@mui/material";
import { useState,useEffect } from "react";


const ProductCard = (props) => {
  const { name, imageUrl, price, hand, tags } = props.data;
  const { renderTags } = props
  const [hover, setHover] = useState(false);


  return (
    <div 
    className="w-full lg:w-1/2 p-3"
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    >
      
      <div
        className="flex flex-row justify-start items-stretch w-full h-60 px-1 py-0 shadow-2xl shadow-gray-300 transition-all cursor-pointer rounded-lg border-0 border-white bg-white hover:border-orange-500 hover:-translate-y-3 fade-up"
        onClick={() => props.onClick(props.data)}
        style={{
          backgroundImage: "url('/assets/logos/watermark-logo.png')",
          backgroundSize: "40% 120%",
          backgroundPosition:  "110% center",
          backgroundRepeat: "no-repeat", 
        }}
      >
        <div 
        className="flex flex-row py-0"
        >
          <img
            className="self-center"
            alt={name}
            src={imageUrl}
            style={{ 
              objectFit: "contain",
              width: "32%",
              height: "90%",

            }}
          />

          <div className="flex flex-col justify-start justify-items-start py-1 w-full">
            <div className="flex flex-row ">
              <div className="w-[80%] h-[20%] flex flex-col items-start figtree-font-bold">
                <h1 style={{ fontSize: "2vmin", textAlign: "center" }}>{i18next.t(name)}</h1>
                <Divider variant='middle'  sx={{ width: "100%", height: "1px", marginLeft: "0" }} className="bg-orange-300" />
              </div>
              <div className="w-[20%] justify-start align-start flex">
                <div 
                  
                  className= {`${!hover ? '' : 'bg-primary'} rounded-full justify-around `}
                >
                <img
                  alt="hand"
                  src={`/assets/hands/${hand}.png`}
                  style={{
                    width: "50px",
                    objectFit: "contain",
                    borderRadius: "50%",
                    filter: !hover ? "none" : "invert(1) brightness(1000%) contrast(1000%)",
                  }}
                />
                </div>
              </div>
            </div>


            <div className="flex flex-col justify-between h-[30%] ">
              <p style={{ fontSize: "1.2vmin", marginTop: 6 }}>
                <span className="figtree-font-bold">Small: </span>  <span className="font-thin figtree-font">${price[PRODUCT_SIZE.SMALL]}</span>
              </p>
              <p style={{ fontSize: "1.2vmin", marginTop: 0 }}>
                <span className="figtree-font-bold">Medium: </span>  <span className="font-thin figtree-font">${price[PRODUCT_SIZE.MEDIUM]}</span>
              </p>
              <p style={{ fontSize: "1.2vmin", marginTop: 0 }}>
                <span className="figtree-font-bold">Large: </span>  <span className="font-thin figtree-font">${price[PRODUCT_SIZE.LARGE]}</span>
              </p>
            </div>
            <div className="flex flex-row w-full h-[20%] mt-4">
              {renderTags(tags)}
            </div>
            
            
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default ProductCard;