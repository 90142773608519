import React from 'react';
import i18next from '../i18n';
import glutenFree from '../assets/icons/tags/glutenFree.svg'
import recommended from '../assets/icons/tags/recommended.svg'
import spicy from '../assets/icons/tags/spicy.svg'
import vegan from '../assets/icons/tags/vegan.svg'
import hasPeanut from '../assets/icons/tags/hasPeanut.svg'
import newProduct from '../assets/icons/tags/newProduct.svg'


export const renderTags = (tags) => {
    const renderedTags = [];

    for (const tag in tags) {
        if (tags[tag]) {
            let content;

            switch (tag) {
                case "recommended":
                    content = (
                        <div className="flex items-center">
                            <img src={recommended} alt="recommended" className="w-6 h-6 mr-2" />
                            {i18next.t('recommended')}
                        </div>
                    );
                    break;

                case "glutenFree":
                    content = (
                        <div className="flex items-center">
                            <img src={glutenFree} alt="Gluten Free" className="w-6 h-6 mr-2" />
                            {i18next.t('glutenfree')}
                        </div>
                    );
                    break;

                case "vegan":
                    content = (
                        <div className="flex items-center">
                            <img src={vegan} alt="vegan" className="w-6 h-6 mr-2" />
                            {i18next.t('vegan')}
                        </div>
                    );
                    break;

                case "hasPeanut":
                    content = (
                        <div className="flex items-center">
                            <img src={hasPeanut} alt="hasPeanut" className="w-6 h-6 mr-2" />
                            {i18next.t('has peanut')}
                        </div>
                    );
                    break;

                case "spicy":
                    content = (
                        <div className="flex items-center">
                            <img src={spicy} alt="spicy" className="w-6 h-6 mr-2" />
                            {i18next.t('spicy')}
                        </div>
                    );
                    break;

                case "newProduct":
                    content = (
                        <div className="flex items-center">
                            <img src={newProduct} alt="newProduct" className="w-6 h-6 mr-2" />
                            {i18next.t('new product')}
                        </div>
                    );
                    break;

                default:
                    content = (
                        <div className="flex items-center">
                            {tag}
                        </div>
                    );
                    break;
            }

            renderedTags.push(
                <div
                    key={tag}
                    className="rounded-full px-4 py-2 mx-2"
                    style={{ backgroundColor: '#EFEFEF' }}
                >
                    {content}
                </div>
            );
        }
    }

    return (
        <div className="flex flex-wrap gap-4">
            {renderedTags}
        </div>
    );
};