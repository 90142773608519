import i18next from "i18next";

const CategoryCard = (props) => {
  const { name, imageUrl, hand } = props.data;

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-4 h-full md:h-80 lg:h-96 " >
      <div
        className="flex flex-col  items-center w-full h-[95%] py-4 shadow-2xl shadow-gray-300 transition-all cursor-pointer rounded-md border-4 border-white bg-white hover:border-orange-500 hover:-translate-y-3 fade-up border border-orange-300 rounded-lg"
        onClick={() => props.onClick(props.data)}
        style={{
          backgroundImage: "url('/assets/logos/watermark-logo.png')",
          backgroundSize: "50% 70%",
          backgroundPosition:  "right center",
          backgroundRepeat: "no-repeat", 
        }}
      >
        <h1 
        className="flex-initial"
        style={{fontSize:"3vmin",textAlign:"center"}}>
          {i18next.t(name)}
        </h1>

        <img
          alt="hand"
          src={`/assets/hands/${hand}.png`}
          style={{
            width: '100px',
            height: '100px',   
            objectFit: 'none', 
            display: 'block'   
          }}
        />

        <img
          className="flex-initial my-1"
          alt={name}
          src={imageUrl}
          width={120}
          height={120}
        />
        
      </div>
    </div>
  )
}

export default CategoryCard;