import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { FilesetResolver, GestureRecognizer } from "@mediapipe/tasks-vision";
import Swal from 'sweetalert2';
import Fuse from 'fuse.js';

import Sidebar from "../layouts/Topbar";
import Category from './Category';
import Product from "./Product";

import LetterModel from '../utils/gesture_recognizer.task';
import { PROCESS, PRODUCT_SIZE, SIZE_GESTURE } from "../utils/enums";
import {countTypes} from "../utils/countTypes";
import { recognizeGestureType, recognizeSelectionType, recognizerSelectSpeech, speakText, speakMP3Promise, stopMP3 } from "../utils/functions";
import ReviewItem from "../components/product/ReviewItem";

import {
  setCategoryConfirmProcess,
  setCategorySelectProcess,
  setCountSelectProcess,
  setOrderConfirmProcess,
  setProductSelectProcess,
  setExtrasSelectProcess,
  setReviewOrderProcess,
  getCategoryById
} from "../store/actions/category.action";
import { 
  addOrder, 
  editOrder,
  deleteOrder, 
  endOrder, 
  saveOrderList 
} from "../store/actions/orderlist.action";
import { debounce } from "@mui/material";

export default function NLPStage({isCam,audio}) {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const dispatch = useDispatch();
  const { list, total } = useSelector(({ orderlist }) => orderlist);
  const { category, products, process: selectionProcess } = useSelector(({ category }) => category);

  const webcamRef = useRef();
  const requestRef = useRef();
  const [webcamRunning, setWebcamRunning] = useState(false);
  const [runningMode, setRunningMode] = useState("IMAGE");
  const [gestureRecognizer, setGestureRecognizer] = useState(null);
  const [detectedData, setDetectedData] = useState([]);
  const [gestureOutput, setGestureOutput] = useState('');
  const [camEnable, setCamEnable] = useState(false);
  const [micEnable, setMicEnable] = useState(false);

  const [editEnable, setEditEnable] = useState(false)
  const [deleteEnable, setDeleteEnable] = useState(false)
  
  const [editId, setEditId] = useState(null)
  const [deleteId, setDeleteId] = useState(null)
  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    dispatch(deleteOrder(deleteId));
    setOpen(false);
  };

  const [reviewOpen, setReviewOpen] = useState(false)
  const [gestureGlobal,setGestureGlobal] = useState(null)
  const [score,setScore] = useState(0)
  const [localProduct, setLocalProduct] = useState({})
  const [localProducts, setLocalProducts] = useState([])
  
  const [finishDialogOpen, setFinishDialogOpen] = useState(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [categoryDialogContent, setCategoryDialogContent] = useState({
    id: '0',
    name: '',
    imageURL: '',
    hand: ''
  });

  const [sizeDialogOpen, setSizeDialogOpen] = useState(false);
  const [countDialogOpen, setCountDialogOpen] = useState(false);
  const [extrasDialogOpen, setExtrasDialogOpen] = useState(false);
  const [selectItemsUttered, setSelectItemsUttered] = useState(false);
  const [ productInstructionsUttered, setProductInstructionsUttered ] = useState(false);
  const [ stopListeningCommands, setStopListeningCommands ] = useState( [] );

  const [product, setProduct] = useState({
    id: '0',
    categoryId: '',
    name: '',
    imageURL: '',
    hand: '',
    price: [0, 0, 0]
  });

  const [customsArray, setCustomsArray] = useState([]);
  const [extrasArray, setExtrasArray] = useState([]);
  const [extrasAndCustoms, setExtrasAndCustoms ] = useState([]);
  const [beenInExtras, setBeenInExtras] = useState(false);

  useEffect(  () => {
    const initialCustoms = product?.customs?.length>0 ? 
                           product.customs.map((custom) => ({ 
                            ...custom, 
                            value: false
                           })) : [];
    const initialExtras = product?.extras?.length>0 ? 
                          product.extras.map((extra) => ({
                            ...extra,
                            isSelected: false
                          })) : [];
    setCustomsArray(initialCustoms);
    setExtrasArray(initialExtras);
  }, [product]);
  useEffect(() => {
    setExtrasAndCustoms(
      [ ...extrasArray.map((item, index) => ({ 
          ...item, originalIndex: index, originalArray: 'extras' })),
      ...customsArray.map((item, index) => ({ 
          ...item, originalIndex: index, originalArray: 'customs' })) ]
    )
  }, [customsArray, extrasArray]);

  const [order, setOrder] = useState({
    id: '0',
    categoryId: '',
    name: '',
    imageURL: '',
    price: [0, 0, 0],
    size: PRODUCT_SIZE.NONE,
    count: 0,
    extras: [],
    customs: []
  });

  const [locale, setLocale] = useState('en');
  const [isSpeaking, setIsSpeaking] = useState(false);


  const navigate = useNavigate();
  const { transcript, interimTranscript, listening, resetTranscript } = useSpeechRecognition();

  const [debouncedTranscript, setDebouncedTranscript] = useState(transcript);
  const location = useLocation();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTranscript(transcript);
    }, 1000); 

    return () => {
      clearTimeout(handler);
    };
  }, [transcript]);

  useEffect(() => {
    if (!listening) return;

    let stopCommands = ['review','stop','shut up', 'halt', 'silence', 'quiet', 'mute', 'stop talking'];
    if (location.pathname === "/" && !extrasDialogOpen && !reviewOpen) {
      let categoriesNames = category.map(item => item.name);
      stopCommands=stopCommands.concat(categoriesNames)
    }else if(!extrasDialogOpen && !reviewOpen){
      let productsNames = products.map(item => item.name);
      stopCommands=stopCommands.concat(productsNames)
    } else if(extrasDialogOpen){
      stopCommands=stopCommands.concat(customsArray.map(item => item.name))
      stopCommands=stopCommands.concat(extrasArray.map(item => item.name))
      stopCommands=stopCommands.concat( ['small','medium','large','confirm','cancel','up','down','increase','decrease','more','less','add','substract','plus','minus','remove','finish'] )
    } else if(reviewOpen){
      stopCommands=stopCommands.concat( list.map( (item,index) => `${index+1}`) )
      stopCommands=stopCommands.concat( list.map( (item) => item.name ) )
      stopCommands=stopCommands.concat( ['finish','confirm','back','back to order','edit','edit order','delete','delete item'] )
    }

    const fuseStopListen = new Fuse(stopCommands, { threshold: 0.4 });
    const results = fuseStopListen.search(debouncedTranscript);

    const handleSpeaking = () => {
      if( results.length>0 ) stopMP3(audio)
    };

    if ( debouncedTranscript ) {
      handleSpeaking();
    }
  }, [debouncedTranscript, listening]);

  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "production") {
    console.warn = function () { }
    console.log = function () { }
  }

  //audios
  const categoryMsg = async (categoryName) => {
    const path = `/assets/audio/${categoryName}.mp3`;
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Al Pausar - Category:', error) }
    
    await speakMP3Promise(audio, path)
    if(!selectItemsUttered) {
      await speakMP3Promise(audio, '/assets/audio/now-select-ite.mp3')
      setSelectItemsUttered(true)
    }
  }
  const selectProductMsg = async (nameURL) => {
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Al Pausar - Product:', error) }

    await speakMP3Promise(audio, nameURL)
    if (!productInstructionsUttered) {
      setProductInstructionsUttered(true)
      await speakMP3Promise(audio, '/assets/audio/now-personaliz.mp3')
      await speakMP3Promise(audio, '/assets/audio/select-size-nu.mp3')
    }
  }
  const productConfirmMsg = async (nameURL) => {
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Al Pausar - productConfirm:', error) }
     
    await speakMP3Promise(audio, nameURL)
    await speakMP3Promise(audio, '/assets/audio/added-successfu.mp3')
  }
  const finishMsg = async () => {
    try { stopMP3(audio) } 
    catch (error) {  console.error('Error Al Pausar - finish:', error) }

    await speakMP3Promise(audio, '/assets/audio/your-order-has-.mp3')
  }

  // Hand gesture recognition
  const predictWebcam = useCallback(() => {
    if (runningMode === "IMAGE") {
      setRunningMode("VIDEO");
      gestureRecognizer.setOptions({ runningMode: "VIDEO" });
    }

    const nowInMs = Date.now();
    const results = gestureRecognizer.recognizeForVideo(webcamRef.current.video, nowInMs);
    if (results.gestures.length > 0) {
      const gesture = results.gestures[0][0];
      setGestureGlobal(results.gestures)
      setScore(gesture.score)
      if (gesture.score > 0.83) { 
        setDetectedData(prevData => [...prevData, { SignDetected: gesture.categoryName }]);
        setGestureOutput(gesture.categoryName);
      } else if( gesture.score > 0.5 && gesture.categoryName === 'g' ){ 
        setDetectedData(prevData => [...prevData, { SignDetected: gesture.categoryName }]);
        setGestureOutput(gesture.categoryName);
      } else {
        setGestureOutput("");
      }
    } else {
      setGestureOutput("");
    }
  
    if (webcamRunning) {
      requestRef.current = requestAnimationFrame(predictWebcam);
    }
  }, [webcamRunning, runningMode, gestureRecognizer]);

  const animate = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate);
    predictWebcam();
  }, [predictWebcam]);

  const enableCam = useCallback(() => {
    if (webcamRunning) {
      setWebcamRunning(false);
      cancelAnimationFrame(requestRef.current);
      setDetectedData([]);
    } else {
      setWebcamRunning(true);
      requestRef.current = requestAnimationFrame(animate);
    }
  }, [webcamRunning, animate]);

  const toggleCam = () => {
    if (camEnable) {
      setWebcamRunning(false);
      cancelAnimationFrame(requestRef.current);
      setDetectedData([]);
      setCamEnable(false);
    } else {
      setMicEnable(false);
      setCamEnable(true);
      setTimeout(() => enableCam(), 2000);
    }
  }

  const toggleMic = () => {
    if (micEnable) {
      setMicEnable(false);
      SpeechRecognition.stopListening();
    } else {
      cancelAnimationFrame(requestRef.current);
      setCamEnable(false);
      setMicEnable(true);
      SpeechRecognition.startListening({ continuous: true, interimResults: true, language: 'en-US' });
    }
  }

  async function loadGestureRecognizer(model) {
    const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
    const recognizer = await GestureRecognizer.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: model,
        delegate: "CPU"
      },
      numHands: 2,
      runningMode: "VIDEO",
    });
    setGestureRecognizer(recognizer);
  }
  useEffect(() => {
    if(!gestureRecognizer) return;
    if(isCam){
      setMicEnable(false);
      setCamEnable(true);
      setTimeout(() => enableCam(), 3000);
    }
    else {
      cancelAnimationFrame(requestRef.current);
      setCamEnable(false);
      setMicEnable(true);
      setTimeout(() => SpeechRecognition.startListening({ continuous: true, interimResults: true }), 3000);
    }
  }, [gestureRecognizer]);

  useEffect(() => {
    loadGestureRecognizer(LetterModel);
  }, []);

  // Effect for gesture recognition
  useEffect(() => {
    switch (selectionProcess) {
      case PROCESS.SELECT_CATEGORY: {
        if (gestureOutput === 'y' && list.length>0) {
          setReviewOpen(true)
          dispatch( setReviewOrderProcess() )
        } else {
          category.map(item => {
            if (item.hand === gestureOutput) {
              setCategoryDialogOpen(true);
              setCategoryDialogContent(item);
              dispatch(setCategoryConfirmProcess());
            }
          });
        }
        break;
      }
      case PROCESS.CONFIRM_CATEGORY: {
        if (gestureOutput === 'confirm') {
          window.speechSynthesis.cancel()
          setCategoryDialogOpen(false);
          dispatch(setProductSelectProcess());
          navigate(`/category/${categoryDialogContent.id}`);
          let categoryName = categoryDialogContent.name.slice(0, 15).toLowerCase().replace(/\s+/g, '-')
          categoryMsg(categoryName)

        } else if (gestureOutput === 'cancel') {
          setCategoryDialogOpen(false);
          dispatch(setCategorySelectProcess());
        }
        break;
      }
      case PROCESS.SELECT_PRODUCT: {
        products.map(item => {
          if (item.hand === gestureOutput) {
            setProduct(item);
            setOrder(prevOrder => ({
              ...item,
              count: 1,
              size: PRODUCT_SIZE.NONE
            }));
            setEditId(null)
            setDeleteId(null)
            setExtrasDialogOpen(true);
            //Audio
            let productName = item.nameUrlMp3
            selectProductMsg(productName)
            dispatch(setExtrasSelectProcess());
          } else if (gestureOutput === 'g') {
            dispatch(setCategorySelectProcess());
            navigate('/');
          } else if (gestureOutput === 'y' && list.length>0) {
            setReviewOpen(true)
            dispatch( setReviewOrderProcess() )
          }
        });
        if (gestureOutput === 'g') {
          dispatch(setCategorySelectProcess());
          navigate('/');
        }
        break;
      }
      case PROCESS.SELECT_EXTRAS: {
        //One Pager
        if ( editId!=null ) {
          handleOnePager(extrasAndCustoms,editId);

        } else {
          handleOnePager(extrasAndCustoms);
        } 
        break;
      }
      case PROCESS.REVIEW_ORDER: {
        if (gestureOutput === 'y') {
          setEditEnable(true)
          setDeleteEnable(false)
          setReviewOpen(false)
          setFinishDialogOpen(true);
          dispatch(setOrderConfirmProcess());
        } else if((gestureOutput === 'g' && !open) || list.length==0){

          setEditEnable(true)
          setDeleteEnable(false)
          setReviewOpen(false)
          if(location.pathname === "/") {
            dispatch(setCategorySelectProcess());
            navigate('/')
          } else {
            dispatch(setProductSelectProcess());
            navigate(`/category/${product.categoryId}`);
          }

        } else if(gestureOutput === 'a' && !open){

          if(!editEnable){
            setEditEnable(!editEnable)
            setDeleteEnable(!deleteEnable)
          }
        } else if(gestureOutput === 'b' && !open){

          
          if(!deleteEnable){
            setEditEnable(!editEnable)
            setDeleteEnable(!deleteEnable)
          }
        } else if( numbers.includes( Number(gestureOutput) ) && editEnable){
          try {
            let idx=Number(gestureOutput)-1;
            setEditId(list[idx].id)
            handleEdit(list[idx])
            //
            setReviewOpen(false)
            dispatch(setProductSelectProcess());

            setExtrasDialogOpen(true);
            dispatch(setExtrasSelectProcess());

          } catch (error) {
            console.error('Error al obtener el ITEM:', error);
          } 
        } else if( numbers.includes( Number(gestureOutput) ) && deleteEnable ){
          try {
            let idx=Number(gestureOutput)-1;
            setDeleteId(list[idx].id)
            setOpen(true);
          } catch (error) {
            console.error('Error al obtener el ITEM:', error);
          } 

        } else if( open && gestureOutput === 'b' && deleteEnable  && deleteId){
          dispatch(deleteOrder(deleteId));
          setOpen(false);

          setEditEnable(true)
          setDeleteEnable(false)
          dispatch(setReviewOrderProcess())

        } else if( open && gestureOutput === 'g' && deleteEnable){
          setOpen(false);
          setGestureOutput('')
          dispatch(setReviewOrderProcess())
        }
        break;
      }
      case PROCESS.CONFIRM_ORDER: {
        if (gestureOutput === 'confirm') {
          confirmOrder();
        } else if (gestureOutput === 'cancel') {
          setFinishDialogOpen(false);
          dispatch(setCategorySelectProcess());
        }
        break;
      }
      default: { }
    }
  }, [gestureOutput]);

  // Effect for voice commands
  useEffect(() => {

    if (debouncedTranscript.length > 0) {
      const handleVoiceCommands = () => {
        const sizeTypes = ["small", "medium", "large"];

        const reviewOpenCommands = [
          { command: 'review' },
          { command: 'view' },

          { command: 'review order' },
          { command: 'view order' },
          { command: 'show order' },
          { command: 'open order' },

          { command: 'open review' },
          { command: 'open review order' },
          { command: 'open view order' },

          { command: 'check review' },
          { command: 'show review' },
          { command: 'open review' },

        ];

        const sizeOptions = {
          includeScore: true,
          threshold: 0.35,
          keys: []
        };

        const countOptions = {
          includeScore: true,
          threshold: 0.35,
          keys: ['number', 'text']
        };

        const extrasAndCustomsOptions = {
          includeScore: true,
          threshold: 0.35,
          keys: ['name']
        };

        const reviewOptions = {
          includeScore: true,
          threshold: 0.35,
          keys: ['command']
        };

        const productsInOrderList = list.map( item => item.name );

        const fuseCategory = new Fuse(category, { keys: ['name'], threshold: 0.35 });
        const fuseProduct = new Fuse(products, { keys: ['name'], threshold: 0.35 });
        const fuseSize = new Fuse(sizeTypes, sizeOptions);
        const fuseCount = new Fuse(countTypes, countOptions);
        const fuseExtrasAndCustoms = ( new Fuse(extrasAndCustoms, extrasAndCustomsOptions) )
        const fuseReview = ( new Fuse(reviewOpenCommands, reviewOptions) )
        const fuseReviewNumbers = ( new Fuse(countTypes, countOptions) )
        const fuseProducListReview = new Fuse(productsInOrderList, { threshold: 0.4 });

        switch (selectionProcess) {
          //0
          case PROCESS.SELECT_CATEGORY: {
            const results = fuseCategory.search(debouncedTranscript);
            const resultsReview = fuseReview.search(debouncedTranscript);

            if (resultsReview.length > 0 && list.length>0) {
              setReviewOpen(true)
              dispatch( setReviewOrderProcess() )
            } else if (results.length > 0) {
              const bestMatch = results[0].item;
              setCategoryDialogOpen(true);
              setCategoryDialogContent(bestMatch);
              dispatch(setCategoryConfirmProcess());
            }
            break;
          }
          //1
          case PROCESS.CONFIRM_CATEGORY: {
            if (recognizerSelectSpeech(debouncedTranscript) === 'confirm') {
              window.speechSynthesis.cancel()
              setCategoryDialogOpen(false);
              dispatch(setProductSelectProcess());
              navigate(`/category/${categoryDialogContent.id}`);
              let categoryName = categoryDialogContent.name.slice(0, 15).toLowerCase().replace(/\s+/g, '-')
              categoryMsg(categoryName)
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'cancel') {
              setCategoryDialogOpen(false);
              dispatch(setCategorySelectProcess());
            }
            break;
          }
          //2
          case PROCESS.SELECT_PRODUCT: {
            const words = debouncedTranscript.split(" ");
            let countMatch = null;
            let sizeMatch = null;
            let productName = "";

            if (words.length > 2) {
              const countResults = fuseCount.search(words[0]);
              const sizeResults = fuseSize.search(words[1]);
              countMatch = countResults.length > 0 ? countResults[0].item.number : null;
              sizeMatch = sizeResults.length > 0 ? sizeResults[0].item : null;

              productName =  countMatch && sizeMatch ? words.slice(2).join(" ") : debouncedTranscript ;
            } else {
              productName = debouncedTranscript;
            }

            const results = fuseProduct.search(productName);
            const resultsReview = fuseReview.search(debouncedTranscript);

            if ( results.length > 0 && recognizerSelectSpeech(debouncedTranscript) !== 'cancel') {
              const bestMatch = results[0].item;
              setProduct(bestMatch);

              if (sizeMatch && countMatch) {
                setOrder({
                  ...order,
                  ...bestMatch,
                  size: sizeMatch,
                  count: Number(countMatch)
                });
                setExtrasDialogOpen(true);
                dispatch(setExtrasSelectProcess());
                //Audio product
                let productName = bestMatch.nameUrlMp3
                selectProductMsg(productName)
                
              } else {
                setOrder({
                  ...order,
                  ...bestMatch,
                  count: 1,
                  size: PRODUCT_SIZE.NONE
                });
                setExtrasDialogOpen(true);
                dispatch(setExtrasSelectProcess());
                //Audio product
                let productName = bestMatch.nameUrlMp3
                selectProductMsg(productName)
                
              }
            } else if (resultsReview?.length > 0 && list.length>0) {
              setReviewOpen(true)
              dispatch( setReviewOrderProcess() )
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'cancel' ) {
              if(beenInExtras){
                setBeenInExtras(false)
                dispatch(setProductSelectProcess());
              }
              else{
                dispatch(setCategorySelectProcess());
                navigate('/');
              }
            }
            break;
          }
          //3 - One Pager
          case PROCESS.SELECT_EXTRAS: {
            setBeenInExtras(true)
            let extraOrCustom = debouncedTranscript
            let res = fuseExtrasAndCustoms.search(extraOrCustom)
            const bestMatch = res[0]?.item;

            if ( extrasAndCustoms.length > 0 && res.length > 0 && editId!=null ) {
              handleOnePagerSpeech(extrasAndCustoms, bestMatch ,editId);
            } else if (extrasAndCustoms.length>0 && res.length>0 && editId==null) {
              handleOnePagerSpeech(extrasAndCustoms, bestMatch);
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'small'){
              setOrder({ ...order, size: PRODUCT_SIZE.SMALL })
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'medium'){
              setOrder({ ...order, size: PRODUCT_SIZE.MEDIUM })
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'large'){
              setOrder({ ...order, size: PRODUCT_SIZE.LARGE })
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'up'){
              setOrder({ ...order, count: order.count + 1 })
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'down'){
              setOrder( { ...order, count: Math.max(order.count - 1, 1) } )
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'confirm' && order.size != PRODUCT_SIZE.NONE) {
              const updatedOrder = {
                ...order,
                extras: extrasArray,
                customs: customsArray
              }
              if(editId != null){
                dispatch(editOrder(updatedOrder,editId));
                let productName = updatedOrder.nameUrlMp3
                productConfirmMsg(productName);
              }
              else {
                dispatch(addOrder(updatedOrder));
                let productName = updatedOrder.nameUrlMp3
                productConfirmMsg(productName);
              }
              setExtrasDialogOpen(false);
              if (editEnable) {
                dispatch(setReviewOrderProcess());
                setReviewOpen(true)
              }
              else {
                if(location.pathname === "/") dispatch(setCategorySelectProcess());
                else {
                  dispatch(setProductSelectProcess());
                }
              }
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'cancel') {
              setDebouncedTranscript('');
              setOrder({ ...order, 
                count: 0,
                extras: [],
                customs: []
              });
              setExtrasDialogOpen(false);
              if (editEnable) {
                  dispatch(setReviewOrderProcess())
                  setReviewOpen(true) 
              }
              else {
                dispatch(setProductSelectProcess());
              }
            }       
            break;
          }
          //4
          case PROCESS.REVIEW_ORDER: {
            const results = fuseReviewNumbers.search(debouncedTranscript);
            const resultsNamesInList = fuseProducListReview.search(debouncedTranscript);
            
            let selection = Number( results[0]?.item?.number )
            let selectionName = resultsNamesInList[0]?.item

            let isNumber = numbers.includes(selection)
            let isName = productsInOrderList.includes(selectionName)
      
            if ( recognizerSelectSpeech(debouncedTranscript) === 'finish' ) {
              setEditEnable(true)
              setDeleteEnable(false)
              setReviewOpen(false)
              setFinishDialogOpen(true);
              dispatch(setOrderConfirmProcess());
      
            } else if( (recognizerSelectSpeech(debouncedTranscript) === 'cancel' && !open) || list.length==0 ){
              setEditEnable(true)
              setDeleteEnable(false)
              setReviewOpen(false)
              if(beenInExtras) {
                dispatch( setReviewOrderProcess() );
                setReviewOpen(true);
              }
              if(location.pathname === "/") {
                dispatch(setCategorySelectProcess());
                navigate('/')
              } else {
                if(beenInExtras) {
                  dispatch(setReviewOrderProcess());
                }
                else dispatch(setProductSelectProcess());
                navigate(`/category/${product.categoryId}`);
              }
            } else if( recognizerSelectSpeech(debouncedTranscript) === 'edit' && !open ){
              if(!editEnable){
                setEditEnable(!editEnable)
                setDeleteEnable(!deleteEnable)
              }    
            } else if( recognizerSelectSpeech(debouncedTranscript) === 'delete' && !open ){
              if(!deleteEnable){
                setEditEnable(!editEnable)
                setDeleteEnable(!deleteEnable)
              }
            } else if( ( isNumber || isName ) && editEnable) {
              try {
                let idx=0;
                if ( isName ) { idx=productsInOrderList.indexOf(selectionName);} 
                else if (isNumber) { idx=selection-1; }
                setEditId(list[idx]?.id)
                handleEdit(list[idx])
                setExtrasDialogOpen(true);
                dispatch(setExtrasSelectProcess());
              } catch (error) {
                console.error('Error al obtener el ITEM:', error);
              } 
            } else if( ( isNumber || isName ) && deleteEnable) {
              try {
                let idx=0;
                if ( isName ) { idx=productsInOrderList.indexOf(selectionName);} 
                else if (isNumber) { idx=selection-1; }
                setDeleteId(list[idx]?.id)
                setOpen(true);
              } catch (error) {
                console.error('Error al borrar el ITEM - Voice:', error);
              } 
            }  else if( open && recognizerSelectSpeech(debouncedTranscript) === 'delete' && deleteEnable  && deleteId){
              dispatch(deleteOrder(deleteId));
              setOpen(false);
    
              setEditEnable(true)
              setDeleteEnable(false)
              dispatch(setReviewOrderProcess())
    
            } else if( open && recognizerSelectSpeech(debouncedTranscript) === 'cancel' && deleteEnable){
              setOpen(false);
              setGestureOutput('')
              dispatch(setReviewOrderProcess())
            }
            break;
          }
          case PROCESS.CONFIRM_ORDER: {
            if (recognizerSelectSpeech(debouncedTranscript) === 'confirm') {
              confirmOrder();
            } else if (recognizerSelectSpeech(debouncedTranscript) === 'cancel') {
              setFinishDialogOpen(false);
              dispatch(setCategorySelectProcess());
            }
            break;
          }
          default: {
            break;
          }
        }
        setTimeout(() => resetTranscript(), 3000);
        setDebouncedTranscript(''); 
      };

      handleVoiceCommands();
    }
  }, [debouncedTranscript, category, products, selectionProcess, dispatch, navigate, resetTranscript]);

  const handleFinishDialogOpen = () => {
      if (list.length > 0 && total > 0) {
        setFinishDialogOpen(true);
        dispatch(setOrderConfirmProcess());
  
    } else {
      Swal.fire({
        icon: "warning",
        toast: true,
        position: "top-right",
        text: "You didn't order any product.",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false
      });
    }
  }

  const handleFinishDialogClose = () => {
    setFinishDialogOpen(false);
    dispatch(setCategorySelectProcess());
  }

  const confirmOrder = () => {
    dispatch(saveOrderList());
    dispatch(setCategorySelectProcess());
    cancelAnimationFrame(requestRef.current);
    setMicEnable(false);
    setCamEnable(false);
    dispatch(endOrder());
    finishMsg();
    navigate('/success');
  }

  const  handleExtras =  (index) => {
    setExtrasArray((prevExtrasArray) => 
      prevExtrasArray.map((extra, i) => 
        i === index ? { ...extra, isSelected: !extra.isSelected } : extra
      )
    );
  };
  const handleCustoms = (index) => {
    setCustomsArray((prevCustomsArray) => 
      prevCustomsArray.map((custom, i) => 
        i === index ? { ...custom, value: !custom.value } : custom
      )
    );
  };
  const handleOnePagerSpeech = (extrasAndCustoms,bestMatch,editIdParam=null)=>{
    for (let item of extrasAndCustoms) {
      if ( item.originalArray === 'extras' && bestMatch.name === item.name) {
        handleExtras(item.originalIndex)
      } else if ( item.originalArray === 'customs' && bestMatch.name === item.name) {
        handleCustoms(item.originalIndex)
     
      } else if (recognizerSelectSpeech(debouncedTranscript) === 'confirm' && order.size != PRODUCT_SIZE.NONE) {
        const updatedOrder = {
          ...order,
          extras: extrasArray,
          customs: customsArray
        }
 
        if (editIdParam != null) {  
          dispatch(editOrder(updatedOrder,editIdParam)) 
          let productName = updatedOrder.nameUrlMp3
          productConfirmMsg(productName);
        } 
        else { 
          dispatch(addOrder(updatedOrder)) 
          let productName = updatedOrder.nameUrlMp3
          productConfirmMsg(productName);
        }
        setExtrasDialogOpen(false);
        if (editEnable) {
          dispatch(setReviewOrderProcess());
          setReviewOpen(true)
        }
        else {
          if(location.pathname === "/") dispatch(setCategorySelectProcess());
          else {
            dispatch(setProductSelectProcess());
          }
        }
        break;  
      } else if (recognizerSelectSpeech(debouncedTranscript) === 'cancel') {
        setOrder({ ...order, 
          count: 0,
          extras: [],
          customs: []
        });
        setExtrasDialogOpen(false);
        if (editEnable) {
          dispatch(setReviewOrderProcess());
          setReviewOpen(true)
        }
        else {
          if(location.pathname === "/") dispatch(setCategorySelectProcess());
          else {
            dispatch(setProductSelectProcess());
          }
        }
        break;  
      }
    }
  }
  const handleOnePager = (extrasAndCustoms,editIdParam=null,deleteIdParam=null)=>{
    if(extrasAndCustoms && extrasAndCustoms.length > 0){
      for (let item of extrasAndCustoms) {
        if (gestureOutput === item.hand && item.originalArray === 'extras') {
          handleExtras(item.originalIndex);
        } else if (gestureOutput === item.hand && item.originalArray === 'customs') {
          handleCustoms(item.originalIndex);
        } else if (gestureOutput === '1') {
          setOrder({ ...order, size: PRODUCT_SIZE.SMALL })
        } else if (gestureOutput === '2') {
          setOrder({ ...order, size: PRODUCT_SIZE.MEDIUM })
        } else if (gestureOutput === '3') {
          setOrder({ ...order, size: PRODUCT_SIZE.LARGE })
        } else if (gestureOutput === 'confirm') {
          setOrder({ ...order, count: order.count + 1 })
        } else if (gestureOutput === 'cancel') {  
          setOrder( { ...order, count: Math.max(order.count - 1, 1) } );
        } else if (gestureOutput === 'y' && order.size != PRODUCT_SIZE.NONE) {
          const updatedOrder = {
            ...order,
            extras: extrasArray,
            customs: customsArray
          }
          
          if(editIdParam != null){
            dispatch(editOrder(updatedOrder,editIdParam));
            let productName = updatedOrder.nameUrlMp3
            productConfirmMsg(productName);
          }else{
            dispatch(addOrder(updatedOrder));
            let productName = updatedOrder.nameUrlMp3
            productConfirmMsg(productName);
          }
          setExtrasDialogOpen(false);
          if (editEnable) {
            dispatch(setReviewOrderProcess());
            setReviewOpen(true)
          }
          else {
            if(location.pathname === "/") dispatch(setCategorySelectProcess());
            else dispatch(setProductSelectProcess());
          }
          break;  
        } else if (gestureOutput === 'g') {
          setOrder({ ...order, 
            count: 0,
            size: PRODUCT_SIZE.NONE,
            extras: [],
            customs: []
          });
          setEditId(null)
          setDeleteId(null)
          setExtrasDialogOpen(false);
          if (editEnable) {
            dispatch(setReviewOrderProcess());
            setReviewOpen(true)
          }
          else {
            if(location.pathname === "/") dispatch(setCategorySelectProcess());
            else dispatch(setProductSelectProcess());
          }
          break;  
        }
      }
      
      //Whithout customs and extras
    } else {
      if (gestureOutput === '1') {
        setOrder({ ...order, size: PRODUCT_SIZE.SMALL })
      } else if (gestureOutput === '2') {
        setOrder({ ...order, size: PRODUCT_SIZE.MEDIUM })
      } else if (gestureOutput === '3') {
        setOrder({ ...order, size: PRODUCT_SIZE.LARGE })
      } else if (gestureOutput === 'confirm') {
        setOrder({ ...order, count: order.count + 1 })
      } else if (gestureOutput === 'cancel') {  
        setOrder( { ...order, count: Math.max(order.count - 1, 1) } );
      } else if (gestureOutput === 'y' && order.size != PRODUCT_SIZE.NONE) {
        const updatedOrder = {
          ...order,
          extras: extrasArray,
          customs: customsArray
        }
        setExtrasDialogOpen(false);
        dispatch(setCategorySelectProcess());
        if(editIdParam != null){
          dispatch(editOrder(updatedOrder,editIdParam));
          let productName = updatedOrder.nameUrlMp3
          productConfirmMsg(productName);
        }else{
          dispatch(addOrder(updatedOrder));
          let productName = updatedOrder.nameUrlMp3
          productConfirmMsg(productName);
        }
        navigate('/');    
      } else if (gestureOutput === 'g') {
        setOrder({ ...order, 
          count: 0,
          size: PRODUCT_SIZE.NONE,
          extras: [],
          customs: []
        });
        setEditId(null)
        setDeleteId(null)
        setExtrasDialogOpen(false);
        dispatch(setProductSelectProcess()); 
      }
    }
  }


  const fetchData = async (catId) => {
    try {
      const data = await dispatch(getCategoryById(catId));
      setLocalProducts(data);
    } catch (error) {
      console.error('FECTH DATA CatId: ', catId, error);
    }
  };

  async function replicateCustomsAndExtras() {
    const updatedExtras = localProduct.extras.map(productExtra => {
        const foundItem = order.extras.find(item => item.name === productExtra.name);
        return {
          ...productExtra,
          isSelected: foundItem ? foundItem.isSelected : false
        };
    });
    setExtrasArray( updatedExtras );
    const updatedCustoms = localProduct.customs.map(productCustom => {
        const foundItem = order.customs.find(item => item.name === productCustom.name);
        return {
          ...productCustom,
          value: foundItem ? foundItem.value : false
        };
    });
    setCustomsArray( updatedCustoms );
  }
  async function handleEdit(itemParam) {
    await fetchData(itemParam.categoryId);
    setOrder(itemParam)
    const tmp = await localProducts.find( item =>  item.name === itemParam.name)
    setLocalProduct( tmp )
    setEditId(itemParam.id)
    try {
      await replicateCustomsAndExtras(); 
    } catch (error) {
      console.error('Error al obtener la categoría EFFECT:', error);
    }
  }

  

  const sidebarMethods = {
    list,
    webcamRef,
    camEnable,
    micEnable,
    listening,
    transcript,
    gestureOutput,
    toggleCam,
    toggleMic,
    finishDialogOpen,
    handleFinishDialogOpen,
    handleFinishDialogClose,
    confirmOrder,
    reviewOpen,
    setReviewOpen,
    gestureGlobal,
    editEnable,
    setEditEnable,
    deleteEnable,
    setDeleteEnable,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    product,
    setProduct,
    order,
    setOrder,
    extrasDialogOpen,
    setExtrasDialogOpen,
    setCustomsArray,
    setExtrasArray,
    audio,
    open,
    setOpen
  };

  const categoryMethods = {
    categoryDialogOpen,
    setCategoryDialogOpen,
    categoryDialogContent,
    setCategoryDialogContent,
    gestureGlobal,
    audio,
    selectItemsUttered,
    setSelectItemsUttered,
    isCam,
    reviewOpen,
    extrasDialogOpen,
    open,
    setOpen
  };

  const productMethods = {
    sizeDialogOpen,
    setSizeDialogOpen,
    countDialogOpen,
    setCountDialogOpen,
    extrasDialogOpen,
    setExtrasDialogOpen,
    product,
    setProduct,
    order,
    setOrder,
    customsArray,
    setCustomsArray,
    extrasArray,
    setExtrasArray,
    handleExtras,
    handleCustoms,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    gestureGlobal,
    audio,
    productInstructionsUttered,
    setProductInstructionsUttered,
    isCam,
    setBeenInExtras,
    reviewOpen,
    open,
    setOpen
  };



  const reviewProps = {
    product,
    order,
    setOrder,
    customsArray,
    setCustomsArray,
    extrasArray,
    setExtrasArray,
    extrasDialogOpen,
    setExtrasDialogOpen,
    handleExtras,
    handleCustoms,
    setCountDialogOpen,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    audio,
    beenInExtras,
    setBeenInExtras,
    open,
    setOpen,
  };

  return (
    
    <main className="flex w-full h-full flex-col ">
      <Sidebar {...sidebarMethods} />
      <div className="relative flex flex-col w-full ">
        {/* <Navbar/> */}
        <Routes>
          <Route exact path="/" element={<Category {...categoryMethods} />} />
          <Route exact path="/category/:id" element={<Product { ...productMethods } />} />
        </Routes>
      </div>

      <ReviewItem { ...reviewProps } />

    </main>
  );
}
