import Fuse from 'fuse.js';
const handSignNames = ['a', 'b', 'c', 'd', 'e', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'x', 'y'];
const handNumberNames = ['a', 'b', 'c', 'd', 'e', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'x', 'y'];

const speechData = {
  'confirm': ['yes', 'confirm', 'ok', 'okay'],
  'cancel': ['no', 'cancel', 'stop', 'back', 'never mind'],
  'finish': ['finish', 'finish order'],
  'start': ['start', 'begin'],
  'small': ['small'],
  'medium': ['medium'],
  'large': ['large'],
  'up': ['up', 'increase', 'more', 'add', 'plus'],
  'down': ['down', 'decrease', 'less', 'subtract', 'minus'],
  'edit': ['edit', 'change', 'modify', 'edit order'],
  'delete': ['delete', 'remove', 'delete order'],
  '1': ['1', 'one'],
  '2': ['2', 'two'],
  '3': ['3', 'three'],
  '4': ['4', 'four'],
  '5': ['5', 'five'],
  '6': ['6', 'six'],
  '7': ['7', 'seven'],
  '8': ['8', 'eight'],
  '9': ['9', 'nine'],
};

const fuseOptions = {
  includeScore: true,
  threshold: 0.3,
  keys: []
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getRandomHandGestures(length) {
  let result = [];
  for (let i = 0; i < length; i++) {
    const index = i % handSignNames.length;
    result.push(handSignNames[index]);
  }
  return result;
}
export function getRandomHandGestures2(length,offset) {
  let result = [];
  for (let i = 0; i < length; i++) {
    const index = (i+offset) % handNumberNames.length;
    result.push(handNumberNames[index]);
  }
  return result;
}

export function assignHandGestures(itemList) {
  let data = itemList;
  let handData = getRandomHandGestures(data.length);
  for (let i = 0; i < data.length; i++) {
    data[i].hand = handData[i];
  }
  return data;
}

export function assignHandGestures2(itemList,offset) {
  let data = itemList;
  let handData = getRandomHandGestures2(data.length,offset);
  for (let i = 0; i < data.length; i++) {
    data[i].hand = handData[i];
  }
  return data;
}
export function recognizerSelectSpeech(transcript) {
  const script = transcript.toLowerCase();
  let catchedData = '';

  const keys = Object.keys(speechData);
  const values = keys.map(key => ({ key, values: speechData[key] }));

  const fuse = new Fuse(values, { ...fuseOptions, keys: ['values'] });
  const results = fuse.search(script);

  if (results.length > 0) {
    catchedData = results[0].item.key;
  }

  return catchedData;
}

export function recognizeSelectionType(transcript) {
  const script = recognizerSelectSpeech(transcript);
  const sizeType = ["small", "medium", "large"];
  const countType = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  if (sizeType.includes(script)) {
    return 'size';
  } else if (countType.includes(script)) {
    return 'count';
  } else {
    return 'other';
  }
}

export function recognizeGestureType(gesture) {
  const sizeType = ["s", "m", "l"];
  const countType = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  if (sizeType.includes(gesture)) {
    return 'size';
  } else if (countType.includes(gesture)) {
    return 'count';
  } else {
    return 'other';
  }
}



function loadVoices() {
  return new Promise((resolve) => {
    let voices = speechSynthesis.getVoices();
    
    if (voices.length !== 0) {
      resolve(voices);
    } else {
      speechSynthesis.onvoiceschanged = () => {
        voices = speechSynthesis.getVoices();
        resolve(voices);
      };
    }
  });
}

export async function speakText(text, lang = 'en-US', voiceName = 'Samantha') {
  if ('speechSynthesis' in window) {
    const voices = await loadVoices();
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = voices.find(voice => voice.lang === lang && voice.name.includes(voiceName));
    utterance.lang = lang;

    utterance.volume = 1;
    utterance.rate = 0.8;
    utterance.pitch = 1.25;

    
    window.speechSynthesis.speak(utterance);
  } else {
    alert('Speech Synthesis API is not available in this browser.');
  }
}

export function stopMP3(audio) {
  return new Promise((resolve) => {
    audio?.current.pause();
  });
}

export function speakMP3Promise(audio, path) {
  return new Promise((resolve) => {
    audio.current.src = path;
    audio.current.onended = resolve;
    audio.current.play();
  });
}

export function speakMP3PromiseMedia(audio, mediaSource) {
  return new Promise((resolve, reject) => {
    try {
      audio.current.src = URL.createObjectURL(mediaSource);
      audio.current.onended = resolve;
      audio.current.onerror = reject;
      audio.current.play();
    } catch (error) {
      reject(error);
    }
  });
}
