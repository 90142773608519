import { useState } from "react";
const ActionButton = ({imageURL, text, ...otherProps}) => {
  const [isHovered, setIsHovered ] = useState(false);

  return (
    <div {...otherProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        display: 'inline-block',
        filter: isHovered ? 'invert(1)' : 'none',
        transition: 'filter 0.3s',
        cursor: 'pointer', // Opcional: indica que el contenedor es interactivo
      }}
    >
      <img alt={imageURL} src={imageURL}
        width={56} height={56}
      />
      <p className='mt-2'>{text}</p>
    </div>
  )
}

export default ActionButton;