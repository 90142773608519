import i18next from 'i18next';
import Backend from 'i18next-http-backend'; // public/locales/{lang}/translation.json
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from "i18next-http-backend";


i18next
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .init({fallbackLng: 'en', 
  debug: true, 
  interpolation: { 
    escapeValue: false 
}
});

export default i18next;
