import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import NLPStage from "./NLPStage";
import Login from './auth/Login';
import Register from './auth/Register';
import Success from './Success';
import NotFound from "./404";
import Modal from '../components/modals/welcomeModal';
import { speakMP3Promise, } from '../utils/functions';

export default function Router() {
  const location = useLocation();
  const currentURL = location.pathname;
  const [isModalOpen, setModalOpen] = useState(true); // State to control the visibility of the modal
  const [pageType, setPageType] = useState(false);
  const isCategoryPath = matchPath({ path: "/", exact: true, strict: true }, currentURL);
  const isProductPath = matchPath({ path: "/category/:id", exact: true, strict: false }, currentURL);
  const audio = useRef(new Audio());

  const handleCloseModal = (variable) => {
    setModalOpen(false);
    if (variable === 1) setPageType(true);
    else if (variable === 2) setPageType(false);
  };


  return (
    <>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} audio={audio}/>

      {!isModalOpen && (
        (isCategoryPath || isProductPath) ? (
          <NLPStage isCam={pageType} audio={audio}/>
        ) : (
          <Routes>
            <Route exact path={'/auth/login'} element={<Login />} />
            <Route exact path={'/auth/register'} element={<Register />} />
            <Route exact path={'/success'} element={<Success />} />
            <Route exact path={'*'} element={<NotFound />} />
          </Routes>
        )
      )
      }
    </>
  )
}