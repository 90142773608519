import * as ActionTypes from '../constants';
import axios from 'axios';
import { ORDER_STATUS, PAYMENT_STATUS, getCategoryName } from '../../utils/enums';
const URL = process.env.REACT_APP_ROOT_API;

export const addOrder = (order) => {
  const regularPrice = order.price[order.size] * order.count;
  order.extras = order.extras.filter(extra => extra.isSelected);
  order.customs = order.customs.filter(custom => custom.value);
  const extrasPrice = order.extras.reduce((acc, extra) => acc + Number(extra.price), 0);
  order.extrasPrice = extrasPrice * order.count;
  const price = regularPrice + order.extrasPrice;
  order.totalPrice = price;

  return {
    type: ActionTypes.ADD_ORDER_LIST,
    payload: {
      order,
      price,
    }
  };
};

export const editOrder = (updatedOrder, orderId) => {
  const regularPrice = updatedOrder.price[updatedOrder.size] * updatedOrder.count;
  updatedOrder.extras = updatedOrder.extras.filter(extra => extra.isSelected);
  updatedOrder.customs = updatedOrder.customs.filter(custom => custom.value);
  const extrasPrice = updatedOrder.extras.reduce((acc, extra) => acc + Number(extra.price), 0);
  updatedOrder.extrasPrice = extrasPrice * updatedOrder.count;
  const price = regularPrice + updatedOrder.extrasPrice;
  updatedOrder.totalPrice = price;

  return {
    type: ActionTypes.EDIT_ORDER_LIST,
    payload: {
      orderId,
      updatedOrder,
    }
  };
};

export const deleteOrder = (orderId) => {
  return {
    type: ActionTypes.DELETE_ORDER_LIST,
    payload: {
      orderId
    }
  };
};


export const endOrder = () => {
  return { type: ActionTypes.FINISH_ORDER };
};

export const saveOrderList = () => {
  return async (dispatch, getState) => {
    const orderList = getState().orderlist.list;

    const ordersByCategory = {};

    orderList.forEach(item => {
      item.category = getCategoryName(item.categoryId);
      if (!ordersByCategory[item.category]) {
        ordersByCategory[item.category] = { Products: [] };
      }

      ordersByCategory[item.category].Products.push({
        category: item.category,
        name: item.name,
        id: item.id,
        size: item.size,
        count: item.count,
        price: item.price[item.size] * item.count,
        status: ORDER_STATUS.IN_PREPARATION,
        extras: item.extras,
        customs: item.customs,
        extrasPrice: item.extrasPrice,
        totalPrice: item.totalPrice,
      });
    });

    try {
      const data = {
        orderList: ordersByCategory,
        status: ORDER_STATUS.IN_PREPARATION,
        paymentStatus: PAYMENT_STATUS.PENDING,
        isDelivered: false,
        creationDate: new Date().toISOString()
      };
      await axios.post(`${URL}/order`, data);
      dispatch({ type: ActionTypes.SAVE_ORDER_LIST_SUCCESS });
    } catch (error) {
      console.error('Error saving order list:', error);
    }
  };
};
