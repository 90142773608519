import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import ProductCard from '../components/product/ProductCard';
import ReviewItem from "../components/product/ReviewItem";

import { PRODUCT_SIZE } from '../utils/enums';
import { getCategoryById, setExtrasSelectProcess, setProductSelectProcess, setCategorySelectProcess } from '../store/actions/category.action';
import i18next from '../i18n';
import { renderTags } from '../utils/tags';
import ProductPagination from '../components/product/ProductPagination';
import { speakMP3Promise, stopMP3 } from '../utils/functions';

export default function Product(props) {
  const {
    setCountDialogOpen,
    extrasDialogOpen,
    setExtrasDialogOpen,
    product,
    setProduct,
    order,
    setOrder,
    customsArray,
    setCustomsArray,
    extrasArray,
    setExtrasArray,
    handleExtras,
    handleCustoms,
    editId,
    setEditId,
    audio,
    productInstructionsUttered,
    setProductInstructionsUttered,
    isCam,
    setBeenInExtras,
    reviewOpen,
  } = props;
  const randomPhrase = [ '/assets/audio/select-an-item.mp3',
                         '/assets/audio/want-to-check-y.mp3',  
                         '/assets/audio/make-sure-your-.mp3',
                       ]



  const handleGoBack = () => {
    dispatch(setCategorySelectProcess());
    navigate('/');
  };

  const selectProductMsg = async (productName)=>{
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Pausar selectProduct (click):', error) }
    
    await speakMP3Promise(audio, productName)
    if (!productInstructionsUttered) {
      setProductInstructionsUttered(true)
      await speakMP3Promise(audio, '/assets/audio/now-personaliz.mp3')
      await speakMP3Promise(audio, '/assets/audio/select-size-nu.mp3')
    }
  }

  const dispatch = useDispatch();
  const { isLoading, products } = useSelector(({ category }) => category);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleExtrasDialogOpen = (data,isEdit=false,idEdit=null) => {
    setProduct(data);
    setOrder(prevOrder => ({
      ...data,
      count: 1,
      size: PRODUCT_SIZE.NONE
    }));
   
    dispatch(setExtrasSelectProcess());
    setExtrasDialogOpen(true);
    //Audio product
    let productName = data.nameUrlMp3
    selectProductMsg(productName)
  }


  const reviewProps = {
    product,
    order,
    setOrder,
    customsArray,
    setCustomsArray,
    extrasArray,
    setExtrasArray,
    extrasDialogOpen,
    setExtrasDialogOpen,
    handleExtras,
    handleCustoms,
    setCountDialogOpen,
    editId,
    setEditId,
    setBeenInExtras
  };

  const handleRandom = async () => {
    const r = isCam ? Math.floor(Math.random() * 3) : Math.floor(Math.random() * 2);
    if (reviewOpen || extrasDialogOpen) await speakMP3Promise(audio,randomPhrase[r]);
  } 

  useEffect(() => {
    dispatch(getCategoryById(id));
    dispatch(setProductSelectProcess());
  }, [dispatch, id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleRandom();
    }, 30000);

    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      {
        isLoading ? (
          <main className='flex justify-center items-center w-full h-full'>
            <LinearProgress className='w-48' />
          </main>
        ) : products.length > 0 ? (
          <main className={'flex flex-col w-full h-full overflow-auto p-8 fade'}>
            <div className='flex flex-row justify-between items-center'>

              <h1 className='font-bold text-5xl mt-2'>{i18next.t('Now, select items to add to your order')}</h1>
              
              <div
              onClick={handleGoBack}
              className='flex flex-row justify-center items-center'
              >
                <img 
                  src='/assets/hands/g-orange.svg' 
                  alt="hand" 
                  objectFit='contain'
                  height={65}
                  width={65}
                />
                <h1 className='text-4xl'>Back</h1>
              </div>

            </div>
            
            <div className='flex flex-wrap w-full h-full my-8'>
              {
                products.map((item) => (
                  <ProductCard
                    key={item.id}
                    data={item}
                    onClick={()=> handleExtrasDialogOpen(item)}
                    renderTags={renderTags}
                  />
                ))
              }
            </div>

            {/* <ProductPagination/> */}

          </main>
        ) : (
          <main className='flex flex-row justify-center mt-8'>
            <div className='flex justify-center items-center w-full h-full pl-60'>
              <h1 className='w-full my-8 text-2xl text-center text-gray-400 fade'>No products for the category.</h1>
            </div>
            <div
              onClick={handleGoBack}
              className='flex flex-row justify-center items-center pr-32'
              >
                <img 
                  src='/assets/hands/g-orange.svg' 
                  alt="hand" 
                  objectFit='contain'
                  height={65}
                  width={65}
                />
                <h1 className='text-4xl'>Back</h1>
              </div>
          </main>
        )
      }

      <ReviewItem { ...reviewProps } />
    </div>
  );
}

