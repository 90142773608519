// SET CATEGORY LIST
export const SET_CATEGORY_LIST_REQUEST = "[CATEGORY] SET LIST REQUEST";
export const SET_CATEGORY_LIST_SUCCESS = "[CATEGORY] SET LIST SUCCESS";
export const SET_CATEGORY_LIST_FAILURE = "[CATEGORY] SET LIST FAILURE";

export const SET_CURRENT_CATEGORY_REQUEST = "[CATEGORY] SET CURRENT LIST BY ID_REQUEST";
export const SET_CURRENT_CATEGORY_SUCCESS = "[CATEGORY] SET CURRENT LIST BY ID_SUCCESS";
export const SET_CURRENT_CATEGORY_FAILURE = "[CATEGORY] SET CURRENT LIST BY ID_FAILURE";

export const GO_TO_CATEGORY_SELECT_PROCESS = "[PROCESS] SELECT CATEGORY";
export const GO_TO_PRODUCT_SELECT_PROCESS = "[PROCESS] SELECT PRODUCT";
export const GO_TO_SIZE_SELECT_PROCESS = "[PROCESS] SELECT SIZE";
export const GO_TO_COUNT_SELECT_PROCESS = "[PROCESS] SELECT COUNT";
export const GO_TO_EXTRAS_SELECT_PROCESS = "[PROCESS] SELECT EXTRAS";
export const GO_TO_REVIEW_ORDER_PROCESS = "[PROCESS] REVIEW ORDER";

export const GO_TO_CATEGORY_CONFIRM_PROCESS = "[PROCESS] CONFIRM CATEGORY";
export const GO_TO_PRODUCT_CONFIRM_PROCESS = "[PROCESS] CONFIRM PRODUCT";
export const GO_TO_ORDER_CONFIRM_PROCESS = "[PROCESS] CONFIRM ORDER";

export const ADD_ORDER_LIST = "[ORDER] ADD NEW LIST";
export const EDIT_ORDER_LIST = "[ORDER] EDIT EXISTING LIST";
export const DELETE_ORDER_LIST = "[ORDER] DELETE EXISTING LIST";
export const FINISH_ORDER = "[ORDER] FINISH";
export const RETRIEVE_ORDER_LIST = "[ORDER] RETRIEVE LIST INFO";
export const SAVE_ORDER_LIST_SUCCESS = "[ORDER] SAVE LIST SUCCESS"; 


export const USER_LOGIN = "[USER] LOGIN";