import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux'; 
import { deleteOrder } from '../../store/actions/orderlist.action';


export default function ConfirmationDelete(props) {
  const { open, setOpen, deleteId, deleteName } = props;
  const dispatch = useDispatch();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    dispatch(deleteOrder(deleteId));
    setOpen(false);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { minWidth: '52%', padding: "40px 150px" } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle className='flex flex-col items-center'>        
        <img 
          src="/assets/warning.png" 
          alt="warning"
          object-fit="contain"
          width={40}
          height={40}
        />
        <h2 className='text-center'> Are you sure you want to delete {deleteName}? </h2>
      </DialogTitle>

      <Box className='flex flex-row content-around  '>
        <Button
              onClick = {handleCancel}
              color="primary"
              component="label"
              variant="outlined"
              tabIndex={-1}
              sx={{ 
              padding: '0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              width: '150%',
              minWidth: '170px',
              marginRight: '2.5rem',
              border: `1px solid gray`,
              }} 
              startIcon={
              <img 
                  src="/assets/hands/g.svg"
                  className={`h-16 w-16 rounded-sm bg-gray-300`}
                  alt="hand"
                  style={{ 
                      objectFit: 'fill',
                      marginLeft: '0.25rem',
                  }}  
              />
              }
          >
          <div className='font-review font-semibold text-black'>Cancel</div>
        </Button>
        <Button
              onClick={handleOk}
              color="primary"
              component="label"
              variant="outlined"
              tabIndex={-1}
              sx={{ 
              padding: '0px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              width: '150%',
              minWidth: '170px',
              marginLeft: '2.5rem',
              }} 
              startIcon={
              <img 
                  src="/assets/hands/b-white.svg"
                  className={`h-16 w-16 rounded-sm bg-primary`}
                  alt="hand"
                  style={{ 
                      objectFit: 'fill',
                      marginLeft: '0.25rem',
                  }}  
              />
              }
          >
          <div className='font-review font-semibold'>Delete Item</div>
        </Button>
      </Box>
    </Dialog>
  );
}

ConfirmationDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

