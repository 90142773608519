import React, { useEffect, useRef, useState, useCallback } from 'react';
import DrivingoLogoWhite from './../../assets/drivingoLogoWhite.svg'
import Ok from './../../assets/icons/okWhite.svg'
import Microphone from './../../assets/icons/microphoneWhite.svg'
import Webcam from "react-webcam";
import VoiceCommands from './../../assets/icons/voiceCommand.svg'
import LetterModel from '../../utils/gesture_recognizer.task';
import { FilesetResolver, GestureRecognizer } from "@mediapipe/tasks-vision";
import { recognizerSelectSpeech, speakMP3, speakMP3Promise } from "../../utils/functions";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Modal = ({ isOpen, onClose, audio }) => {
    
  const [webcamRunning, setWebcamRunning] = useState(false);
  const webcamRef = useRef();
  const requestRef = useRef();
  const [detectedData, setDetectedData] = useState([]);
  const [gestureOutput, setGestureOutput] = useState('');
  const [runningMode, setRunningMode] = useState("IMAGE");
  const [gestureRecognizer, setGestureRecognizer] = useState(null);
  const [camStart, setCamStart] = useState(false);
  const [voiceStart, setVoiceStart] = useState(false);
  const [camEnable, setCamEnable] = useState(false);
  const [micEnable, setMicEnable] = useState(false);
  const { transcript, interimTranscript, listening, resetTranscript } = useSpeechRecognition();
  const audioW = useRef(new Audio());


  const [debouncedTranscript, setDebouncedTranscript] = useState(transcript);
 
  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.keyCode === 27) onClose();
    }
    
    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
      return () => document.removeEventListener('keydown', handleEscapeKey);
    }
  }, [isOpen, onClose]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTranscript(transcript);
    }, 1000); // Delay of 1 second

    return () => {
      clearTimeout(handler);
    };
  }, [transcript]);

  useEffect(() => {
    if(!gestureRecognizer || camEnable) return;
    setCamEnable(true);
    setTimeout(() => {
      enableCam();
      setMicEnable(true);
      SpeechRecognition.startListening({ continuous: true, interimResults: true });
    }, 2000);
  }, [gestureRecognizer]);
  const gestureMsg = async ()=>{ 
    await speakMP3Promise(audio,'/assets/audio/follow-the-sign.mp3')
    await speakMP3Promise(audio,'/assets/audio/please-select-.mp3')
    await speakMP3Promise(audio,'/assets/audio/your-order-tota.mp3')
  }

  const voiceMsg = async ()=>{
    await speakMP3Promise(audio,'/assets/audio/use-your-voice-.mp3')
    await speakMP3Promise(audio,'/assets/audio/please-select-.mp3')
    await speakMP3Promise(audio,'/assets/audio/your-order-tota.mp3')
  }
  useEffect(() => {
    if(!gestureOutput) return;
    if(gestureOutput === "confirm") {
      gestureMsg();
      setCamStart(true);
      cancelAnimationFrame(requestRef.current);
      setCamEnable(false);
      setTimeout(() => {
        onClose(1);
      }, 2000);
    }
  }, [gestureOutput]);

  useEffect(() => {
    if (debouncedTranscript.length > 0) {
      const  handleVoiceCommands = async () => {
        if (recognizerSelectSpeech(debouncedTranscript) === 'start' || debouncedTranscript === 'start' || transcript === 'start') {
          setVoiceStart(true);
          voiceMsg();
          setTimeout(() => {
            onClose(2);
          }, 2000);
        }
        setTimeout(() => resetTranscript(), 2000);
        setDebouncedTranscript('');
      };
      handleVoiceCommands();
    }
  }, [debouncedTranscript, resetTranscript]);

  // Hand gesture recognition
  const predictWebcam = useCallback(() => {
    if(!webcamRef.current || !webcamRef.current.video) return;

    const nowInMs = Date.now();
    const results = gestureRecognizer.recognizeForVideo(webcamRef.current.video, nowInMs);
    
    if (results.gestures.length > 0) {
      setDetectedData(prevData => [...prevData, { SignDetected: results.gestures[0][0].categoryName }]);
      setGestureOutput(results.gestures[0][0].categoryName);
    } else {
      setGestureOutput("");
    }

    if (webcamRunning) {
      requestRef.current = requestAnimationFrame(predictWebcam);
    }
  }, [webcamRunning, runningMode, gestureRecognizer]);

  const animate = useCallback(() => {
    requestRef.current = requestAnimationFrame(animate);
    predictWebcam();
  }, [predictWebcam]);

  const enableCam = useCallback(() => {
    if (webcamRunning) {
      setWebcamRunning(false);
      cancelAnimationFrame(requestRef.current);
      setDetectedData([]);
    } else {
      setWebcamRunning(true);
      requestRef.current = requestAnimationFrame(animate);
    }
  }, [webcamRunning, animate]);


  async function loadGestureRecognizer(model) {
    const vision = await FilesetResolver.forVisionTasks("https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm");
    const recognizer = await GestureRecognizer.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: model,
        delegate: "CPU"
      },
      numHands: 2,
      runningMode: "VIDEO"
    });
    setGestureRecognizer(recognizer);
  }

  const handleClickStart = () => {
    onClose();
    try {
      voiceMsg();
    } catch (error) {
      console.info("error")
    }
    
  };
  const welcomeMsg = async () => {
      await speakMP3Promise(audio,'/assets/audio/welcome-to-drib.mp3')
  }
  useEffect(() => {
    loadGestureRecognizer(LetterModel);
    setTimeout(() => {
      if(audio.current.paused || audio.current.ended) welcomeMsg() 
    }, 500);
  }, []);


  if (!isOpen) return null;

  return (
    <>
      <div className="flex background-image flex-col justify-start items-center min-h-screen w-full p-10 pb-4">
        <div className='flex lg:mt-16 lg:mb-8'>
          <h2 className="text-5xl font-bold text-white">Welcome to</h2>
        </div>
        <img src={DrivingoLogoWhite} alt="Logo" className="h-12 lg:h-36 w-auto ml-1 mt-1" />
        <p className="text-center text-white font-semibold mb-4 lg:mt-8 lg:mb-8 text-xl lg:text-3xl">Upgrade your drive-thru experience with our innovative </p> <br />
        <p className="text-center text-white font-semibold mb-4 text-xl lg:text-3xl">system, making ordering easier than ever.</p>
        <div className='flex items-center flex-col lg:h-96 lg:mt-8'>
          <div className="flex border-white border justify-evenly items-center bg-customOrangeWelcome py-2 px-4 rounded-lg mb-8 lg:w-[34rem] lg:h-[6rem]">
            <img src={Ok} alt="ok" className="h-16 w-auto ml-1 self-center flex mr-4" />
            <p className='text-white lg:text-2xl'>Follow the signs displayed <br /> for each menu item</p>
          </div>
          <div className='flex items-center max-h-[75%]'>
            <p className='text-white align-middle text-2xl lg:mb-4'> or </p>
          </div>
          <div className="lg:text-2xl flex border-white border justify-evenly items-center bg-customOrangeWelcome py-2 px-4 rounded-lg mt-8 lg:w-[34rem] lg:h-[6rem]">
            <img src={Microphone} alt="microphone" className="h-14 w-auto ml-1 self-center flex mr-4" />
            <p className='text-white '>Use your voice as command</p>
          </div>
        </div>
        
        <hr className="border border-white max-w-[80%] w-full" />

        <div className='flex flex-col items-center w-full lg:h-[35%] lg:mt-24'>
          <p className="text-2xl font-bold text-white py-2 my-2 lg:mb-24 lg:text-3xl">Now, let's start!</p>
          <div className='flex w-full items-center justify-center'>
            <div className={`flex justify-evenly flex-col border-white border items-center bg-customOrangeWelcome py-2 px-4 mx-4 rounded-lg mb-4 w-2/5 transition-all duration-2000 ${voiceStart ? 'back' : ''}  ${camStart ? 'h-[38rem]' : 'h-[32rem]'}`}>
              <p className='text-white text-center'>Using signs:
              <p className='text-white font-semibold'><span className={!camStart?`text-xl`:`text-3xl`}>thumbs up!</span> {!camStart?'to start':''}</p>

              </p>
              <div className="relative w-80 h-48 border-2 rounded-md my-4 bg-gray-500 overflow-hidden">
                {
                  enableCam && (
                    <Webcam
                      ref={webcamRef}
                      audio={false}
                      className="absolute top-0 left-0 w-full"
                      mirrored
                    />
                  )
                }
              </div>
            </div>
              <div className={`flex h-80 justify-evenly flex-col border-white border items-center bg-customOrangeWelcome py-2 px-4 mx-4 rounded-lg mb-4 w-2/5 transition-all duration-2000 ${voiceStart ? 'h-[38rem]' : 'h-[32rem]'} ${camStart ? 'back' : ''}`}>
                <div className='flex flex-col items-center self-center'>
                  <p className='text-white'>Using voice commands:</p>
                  <p className='text-white font-semibold'>Say<span className="text-xl">"Start!"</span> to begin.</p>
                </div>
                <div className='h-full w-full lg:w-80 lg:h-[14rem] flex items-center justify-center'>
                  <img src={VoiceCommands} alt="voiceCommands" className="h-14 w-auto ml-1 self-center flex mr-4" />
                </div>
              </div>
            
                    
          </div>
        </div>
        
        <button className="mt-2 w-[20%] py-2 bg-orange-500 text-white rounded-full cursor-pointer text-xl" onClick={handleClickStart}>
          Start
        </button>
      </div>
    </>
  );
};

export default Modal;
