import React, { Suspense, useState, useEffect } from 'react'; // Correctly import Suspense
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'; // Renamed to avoid confusion with React's context provider
import { ThemeProvider, createTheme } from '@mui/material/styles';
import store from './store';
import Routes from './routes'; // Assuming this is your routing component
import './i18n'; // Importing for side effects (initialization)
import LocaleContext from './LocaleContext';
import i18next from 'i18next';
// import AuthProvider from './components/AuthProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: "#F56200"
    },
    secondary: {
      main: "#FFFFFF" // Changed from "#white" to a valid hex color
    }
  }
});

function Loading() {
  return <div>Loading...</div>;
}


export default function App() {
  const [locale, setLocale] = useState(i18next.language);

  i18next.on('languageChanged', (lng) => setLocale(i18next.language));

  const handleChange = (event) => {
    i18next.changeLanguage(event.target.value);
  }

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <HashRouter>
{/* Uncomment AuthProvider if it's needed in your routing logic */}
              {/* <AuthProvider> */}
              <Suspense fallback={<Loading />}>
              <Routes />
            </Suspense>
            {/* </AuthProvider> */}
          </HashRouter>
        </ThemeProvider>
      </ReduxProvider>
    </LocaleContext.Provider>
  );
}
