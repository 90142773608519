import Webcam from "react-webcam";
//import { ReactMic } from 'react-mic';
import { Box, Fab, Button, Dialog, DialogContent, Container } from "@mui/material";
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import ReviewOrder from "../components/product/ReviewOrder";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setReviewOrderProcess } from "../store/actions/category.action";

import ActionButton from "../components/product/ActionButton";

import i18next from 'i18next';

export default function Sidebar(props) {
  const {
    webcamRef,
    camEnable,
    micEnable,
    toggleCam,
    toggleMic,
    finishDialogOpen,
    handleFinishDialogOpen,
    handleFinishDialogClose,
    confirmOrder,
    extrasDialogOpen,
    setExtrasDialogOpen,
    reviewOpen,
    setReviewOpen,
    gestureGlobal,
    editEnable,
    setEditEnable,
    deleteEnable,
    setDeleteEnable,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    product,
    setProduct,
    order,
    setOrder,
    setCustomsArray,
    setExtrasArray,
    audio,
    open,
    setOpen
  } = props;


  const { list, total } = useSelector(({ orderlist }) => orderlist);
  const dispatch = useDispatch();




  function openReviewModal() {
    if ( list.length>0 ){
      dispatch( setReviewOrderProcess() );
      setReviewOpen(true)
    } 
  }
  
  const reviewOrderProps = {
    handleFinishDialogOpen,
    reviewOpen,
    setReviewOpen,
    list,
    total,
    extrasDialogOpen,
    setExtrasDialogOpen,
    editEnable,
    setEditEnable,
    deleteEnable,
    setDeleteEnable,
    editId,
    setEditId,
    deleteId,
    setDeleteId,
    product,
    setProduct,
    order,
    setOrder,
    setCustomsArray,
    setExtrasArray,
    audio,
    open,
    setOpen
  };

  return (
    <>
      <Box
        id="top-bar-container" 
        className="flex flex-row  h-[254px] " 
      >
        <img className="flex-none w-[29%] h-full px-12 py-4"
          src={'/assets/logos/drivingoLogo.svg'} 
          alt="img" 
        />
        <div className="flex-none w-[1%] h-full flex flex-col justify-center">
          <div className="w-[2px] h-[80%] bg-orange-300 rounded-full"></div>
        </div>

        <Box 
          className="flex-none w-[40%] h-full flex flex-col justify-center  items-center"
        >
          <div className="flex-1 flex flex-col justify-end">
            <span className="text-xl">{i18next.t('Want to check your order?')}</span>
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <Button
              onClick={ openReviewModal }
              disabled={!(list.length>0) }
              color="primary"
              component="label"
              variant="outlined"
              tabIndex={-1}
              sx={{ padding: '0px 4px' }} 
              startIcon={
                <img 
                src={!(list.length>0) ? '/assets/hands/y.svg' : '/assets/hands/y-white.svg'}
                className={`h-16 w-16 rounded-sm ${!(list.length>0) ? 'bg-gray-500' : 'bg-primary'} ${!(list.length > 0) ? 'filter invert' : ''}`}
                alt="hand" />
              }
            >
                <div>Review Order</div>
            </Button>
          </div>
          
          <div className="flex-1 flex flex-col justify-start items-center text-xl"
          >
            <div> 
              <span  style={{ fontWeight: '900' }}> Your order total: </span>
              <span style={{ fontWeight: '100' }}>${ isNaN(  (Math.round(total * 100) / 100) ) ?  '0.00' : (Math.round(total * 100) / 100).toFixed(2) }</span> 
            </div>
          </div>

        </Box>

        <Box className="flex-none w-[30%] h-full bg-white flex items-center justify-center">
          <div className="relative w-[100%] h-full border-2 rounded-md my-4 bg-gray-500 overflow-hidden">
            {
              camEnable && (
                <Webcam
                  ref={webcamRef}
                  audio={false}
                  className="absolute top-0 left-0 w-full"
                  mirrored
                />
              )
            }

            {
              micEnable && (
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full bg-white">
                  {/* <ReactMic record={listening} /> */}
                </div>
              )
            }

            <div className="flex justify-center items-center absolute bottom-1 left-0 w-full">
              <Fab size="medium" sx={{ margin: 1 }} onClick={toggleCam}>
                {camEnable ? <VideocamIcon /> : <VideocamOffIcon />}
              </Fab>

              <Fab size="medium" sx={{ margin: 1 }} onClick={toggleMic}>
                {micEnable ? <MicIcon /> : <MicOffIcon />}
              </Fab>
            </div>
          </div>
        </Box>

      </Box>

      <ReviewOrder { ...reviewOrderProps }/>


      <Dialog open={finishDialogOpen} onClose={handleFinishDialogClose}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: 600,
            padding: 48
          }}
        >
          <img alt="banner" src={'/assets/finish_order.png'} width={256} height={256} />
          <h1 className="text-2xl text-primary">{i18next.t('Are you sure you want to finish your order?')}</h1>

          <div className='flex justify-center items-center w-full pt-12 pb-4'>
            <ActionButton
              imageURL={'/assets/hands/yes.png'}
              text={i18next.t('Confirm')}
              className='flex flex-col justify-center items-center mx-16 cursor-pointer transition-all hover:-translate-y-2'
              onClick={confirmOrder}
            />

            <ActionButton
              imageURL={'/assets/hands/no.png'}
              text={i18next.t('Cancel')}
              className='flex flex-col justify-center items-center mx-16 cursor-pointer transition-all hover:translate-y-2'
              onClick={handleFinishDialogClose}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}