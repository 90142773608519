export const countTypes = [
    { number: '1', text: 'one' },
    { number: '2', text: 'two' },
    { number: '3', text: 'three' },
    { number: '4', text: 'four' },
    { number: '5', text: 'five' },
    { number: '6', text: 'six' },
    { number: '7', text: 'seven' },
    { number: '8', text: 'eight' },
    { number: '9', text: 'nine' },
    { number: '10', text: 'ten' },
    { number: '11', text: 'eleven' },
    { number: '12', text: 'twelve' },
    { number: '13', text: 'thirteen' },
    { number: '14', text: 'fourteen' },
    { number: '15', text: 'fifteen' },
    { number: '16', text: 'sixteen' },
    { number: '17', text: 'seventeen' },
    { number: '18', text: 'eighteen' },
    { number: '19', text: 'nineteen' },
    { number: '20', text: 'twenty' },
    { number: '21', text: 'twenty-one' },
    { number: '22', text: 'twenty-two' },
    { number: '23', text: 'twenty-three' },
    { number: '24', text: 'twenty-four' },
    { number: '25', text: 'twenty-five' },
    { number: '26', text: 'twenty-six' },
    { number: '27', text: 'twenty-seven' },
    { number: '28', text: 'twenty-eight' },
    { number: '29', text: 'twenty-nine' },
    { number: '30', text: 'thirty' },
    { number: '31', text: 'thirty-one' },
    { number: '32', text: 'thirty-two' },
    { number: '33', text: 'thirty-three' },
    { number: '34', text: 'thirty-four' },
    { number: '35', text: 'thirty-five' },
    { number: '36', text: 'thirty-six' },
    { number: '37', text: 'thirty-seven' },
    { number: '38', text: 'thirty-eight' },
    { number: '39', text: 'thirty-nine' },
    { number: '40', text: 'forty' },
    { number: '41', text: 'forty-one' },
    { number: '42', text: 'forty-two' },
    { number: '43', text: 'forty-three' },
    { number: '44', text: 'forty-four' },
    { number: '45', text: 'forty-five' },
    { number: '46', text: 'forty-six' },
    { number: '47', text: 'forty-seven' },
    { number: '48', text: 'forty-eight' },
    { number: '49', text: 'forty-nine' },
    { number: '50', text: 'fifty' },
    { number: '51', text: 'fifty-one' },
    { number: '52', text: 'fifty-two' },
    { number: '53', text: 'fifty-three' },
    { number: '54', text: 'fifty-four' },
    { number: '55', text: 'fifty-five' },
    { number: '56', text: 'fifty-six' },
    { number: '57', text: 'fifty-seven' },
    { number: '58', text: 'fifty-eight' },
    { number: '59', text: 'fifty-nine' },
    { number: '60', text: 'sixty' },
    { number: '61', text: 'sixty-one' },
    { number: '62', text: 'sixty-two' },
    { number: '63', text: 'sixty-three' },
    { number: '64', text: 'sixty-four' },
    { number: '65', text: 'sixty-five' },
    { number: '66', text: 'sixty-six' },
    { number: '67', text: 'sixty-seven' },
    { number: '68', text: 'sixty-eight' },
    { number: '69', text: 'sixty-nine' },
    { number: '70', text: 'seventy' },
    { number: '71', text: 'seventy-one' },
    { number: '72', text: 'seventy-two' },
    { number: '73', text: 'seventy-three' },
    { number: '74', text: 'seventy-four' },
    { number: '75', text: 'seventy-five' },
    { number: '76', text: 'seventy-six' },
    { number: '77', text: 'seventy-seven' },
    { number: '78', text: 'seventy-eight' },
    { number: '79', text: 'seventy-nine' },
    { number: '80', text: 'eighty' },
    { number: '81', text: 'eighty-one' },
    { number: '82', text: 'eighty-two' },
    { number: '83', text: 'eighty-three' },
    { number: '84', text: 'eighty-four' },
    { number: '85', text: 'eighty-five' },
    { number: '86', text: 'eighty-six' },
    { number: '87', text: 'eighty-seven' },
    { number: '88', text: 'eighty-eight' },
    { number: '89', text: 'eighty-nine' },
    { number: '90', text: 'ninety' },
    { number: '91', text: 'ninety-one' },
    { number: '92', text: 'ninety-two' },
    { number: '93', text: 'ninety-three' },
    { number: '94', text: 'ninety-four' },
    { number: '95', text: 'ninety-five' },
    { number: '96', text: 'ninety-six' },
    { number: '97', text: 'ninety-seven' },
    { number: '98', text: 'ninety-eight' },
    { number: '99', text: 'ninety-nine' },
    { number: '100', text: 'one hundred' }
  ];
  