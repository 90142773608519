export const PRODUCT_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  NONE: 'none'
}

export const PROCESS = {
  SELECT_CATEGORY: 0,
  CONFIRM_CATEGORY: 1,
  SELECT_PRODUCT: 2,
  SELECT_EXTRAS: 3,

  REVIEW_ORDER: 4,
    
  CONFIRM_PRODUCT: 5,
  CONFIRM_ORDER: 6
}

export const SIZE_GESTURE = {
  's': PRODUCT_SIZE.SMALL,
  'm': PRODUCT_SIZE.MEDIUM,
  'l': PRODUCT_SIZE.LARGE
}
export const ORDER_STATUS = {
  IN_PREPARATION: 'in preparation',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  READY: 'ready'
};

export const PAYMENT_STATUS = {
  PENDING: 'pending',
  PAID: 'paid'
}

export const getCategoryName = (categoryId) => {
  const categoryMap = {
    '1': 'Burgers',
    '2': 'Desserts',
    '3': 'Drinks',
    '4': 'Fries',
    '5': 'Fruits',
    '6': 'Meals'
  };
  return categoryMap[categoryId] || 'Unknown';
};
