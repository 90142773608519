import axios from "axios";
//import '../mockApi';
import { assignHandGestures, assignHandGestures2 } from "../../utils/functions";
import * as ActionTypes from '../constants';
const URL = process.env.REACT_APP_ROOT_API;

export const getCategoryList = () => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_CATEGORY_LIST_REQUEST });
  try {
    const response = await axios.get(`${URL}/categories`);
    const categories = assignHandGestures(response.data.result);

    dispatch({
      type: ActionTypes.SET_CATEGORY_LIST_SUCCESS,
      payload: categories
    });

    return categories;
  } catch (error) {
    console.error(error);
    dispatch({
      type: ActionTypes.SET_CATEGORY_LIST_FAILURE,
      error
    });

    throw error;
  }
}

export const getCategoryById = (id) => async (dispatch) => {
  dispatch({ type: ActionTypes.SET_CURRENT_CATEGORY_REQUEST });

  try {
    // Send a POST request instead of a GET request
    const response = await axios.post(`${URL}/productsByCategory`, { categoryId: id });
    let data = assignHandGestures(response.data.result);

    for (let x = 0; x < data.length; x++) {
      let newCustoms = data[x]?.customs ?
      Object.keys(data[x].customs).map((key) => ({ 
        name: key,
        value: data[x].customs[key] 
      })) : [];
      newCustoms = newCustoms.filter((item) => item?.value == true);

      let newExtras = data[x]?.extras ?
      Object.keys(data[x].extras).map((key) => ({ 
        name: key, 
        ...data[x].extras[key] 
      })) : [];
      newExtras = newExtras.filter((item) => item?.isSelected == true);

      newCustoms = assignHandGestures2(newCustoms,0);
      newExtras = assignHandGestures2(newExtras,12);
      data[x].customs = newCustoms;
      data[x].extras = newExtras;

    }

    dispatch({
      type: ActionTypes.SET_CURRENT_CATEGORY_SUCCESS,
      payload: data
    });
    return data;
  } catch (error) {
    dispatch({
      type: ActionTypes.SET_CURRENT_CATEGORY_FAILURE,
      error
    });

    throw error; // Throw the error to handle it elsewhere if needed
  }
}


export const setCategorySelectProcess = () => {
  return { type: ActionTypes.GO_TO_CATEGORY_SELECT_PROCESS }
}

export const setProductSelectProcess = () => {
  return { type: ActionTypes.GO_TO_PRODUCT_SELECT_PROCESS }
}

export const setSizeSelectProcess = () => {
  return { type: ActionTypes.GO_TO_SIZE_SELECT_PROCESS };
}

export const setCountSelectProcess = () => {
  return { type: ActionTypes.GO_TO_COUNT_SELECT_PROCESS };
}

export const setExtrasSelectProcess = () => {
  return { type: ActionTypes.GO_TO_EXTRAS_SELECT_PROCESS };
}

export const setCategoryConfirmProcess = () => {
  return { type: ActionTypes.GO_TO_CATEGORY_CONFIRM_PROCESS };
}

export const setProductConfirmProcess = () => {
  return { type: ActionTypes.GO_TO_PRODUCT_CONFIRM_PROCESS };
}

export const setOrderConfirmProcess = () => {
  return { type: ActionTypes.GO_TO_ORDER_CONFIRM_PROCESS };
}

export const setReviewOrderProcess = () => {
  return { type: ActionTypes.GO_TO_REVIEW_ORDER_PROCESS };
}