import { Dialog, DialogContent, DialogTitle, Divider, Card, Box, Button, Grid } from '@mui/material';
import i18next from '../../i18n';
import { PRODUCT_SIZE } from '../../utils/enums';
import { capitalizeFirstLetter } from '../../utils/functions';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addOrder,editOrder } from '../../store/actions/orderlist.action';
import { setCategorySelectProcess, setCountSelectProcess, setProductSelectProcess, } from '../../store/actions/category.action';
import { speakMP3Promise, stopMP3 } from '../../utils/functions';

export default function ReviewItem( props ) {
  const {
    order,
    setOrder,
    customsArray,
    extrasArray,
    extrasDialogOpen,
    setExtrasDialogOpen,
    handleExtras,
    handleCustoms,
    editId,
    setEditId,
    audio,
    beenInExtras,
    setBeenInExtras,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const getGridSize = (length) => {
    if (length < 10) return 4;
    if (length <= 15) return 6;
    if (length < 20) return 8;    
    if (length >= 20) return 12;                  
  };

  const [totalExtras, setTotalExtras] = useState(0);


  useEffect(() => {
    totalExtrasSum();
  }, [extrasArray]);


  useEffect(() => {
    if (extrasDialogOpen) setBeenInExtras(true);
    else setBeenInExtras(false);

  }, [extrasDialogOpen]);

  const totalExtrasSum =  () => {
    let total = 0;
    extrasArray.forEach((extra) => {
      if (extra.isSelected) {
        total += parseFloat(extra.price);
      }
    });
    setTotalExtras( total );
  };
  const handleExtrasDialogClose = () => {
    setOrder(prevOrder => ({
      ...prevOrder,
      count: 0,
      extras: [],
      customs: []
    }));
    setExtrasDialogOpen(false);
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Pausar reviewList:', error) }
    if(location.pathname === '/') dispatch(setCategorySelectProcess());
    else dispatch( setProductSelectProcess() );
  }

  const handleConfirmDialogClose = () => {
    setOrder({ ...order, count: 0, extras: [], customs: [] });
    setExtrasDialogOpen(false);
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Pausar reviewList:', error) }
    dispatch(setProductSelectProcess());
  }

  const productConfirmMsg = async (productName)=>{
    try { stopMP3(audio) 
      await speakMP3Promise(audio, productName)
    await speakMP3Promise(audio, '/assets/audio/added-successfu.mp3')
    } 
    catch (error) { console.error('Error Al Pausar - productConfirm :', error) }
    
  }
  const addOrderlist = () => {
    const updatedOrder = {
      ...order,
      extras: extrasArray,
      customs: customsArray,
    }
    if(editId != null){
      dispatch(editOrder(updatedOrder,editId));
      let productName = updatedOrder.nameUrlMp3
      productConfirmMsg(productName);
    }else{
      dispatch(addOrder(updatedOrder));
      let productName = updatedOrder.nameUrlMp3
      productConfirmMsg(productName);
    }
    setEditId(null)
    setExtrasDialogOpen(false);
    if(location.pathname === '/') dispatch(setCategorySelectProcess());
    else dispatch( setProductSelectProcess() );
  }
  useEffect(() => {
    const preloadImage1 = new Image();
    preloadImage1.src = '/assets/hands/1-white.svg'
    const preloadImage2 = new Image();
    preloadImage2.src = '/assets/hands/2-white.svg'
    const preloadImage3 = new Image();
    preloadImage3.src = '/assets/hands/3-white.svg'
    
    customsArray.map((custom) => {
      const preloadImage = new Image();
      preloadImage.src = `/assets/hands/${custom.hand}-white.svg`;
    })
    extrasArray.map((extra) => {
      const preloadImage = new Image();
      preloadImage.src = `/assets/hands/${extra.hand}-white.svg`;
    })

  }, []);

  return (
    <>
    <Dialog 
    open={extrasDialogOpen} 
    onClose={handleExtrasDialogClose}
    maxWidth='sm'
    fullWidth
    >
        <DialogTitle 
        className='flex justify-center items-center'
        maxWidth='sm' 
        fullWidth 
        >
          <p className='font-review text-primary text-center text-4xl py-4 w-96'>{i18next.t('Review Item')}</p>
        </DialogTitle>

        <DialogContent 
        className='flex justify-center items-center p-4'
        sx={{
          '.MuiBox-root': {
            width: '100%', 
          },
        }}
        >
          <Box className='flex flex-col justify-center'
          maxWidth='sm' 
          fullWidth
          >
            <Card
            maxWidth='sm' 
            fullWidth
              sx={{
                boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.05)",
                display: 'flex',
                justifyContent: 'center',  
                alignItems: 'center',      
                border: '1px',
                margin: '4px',
                borderRadius: '2px',
              }}
              
            >
              <img
                className = 'w-[%100] h-[200px] object-contain'
                alt={order.imageUrl}
                src={order.imageUrl}                
              />
            </Card>

            <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',  
              border: '1px',
              margin: '4px',
              paddingRight: '10px',
              borderRadius: '2px',
            }}
            > 
              <Box className='p-2'>
                <header>
                  <h1 className='text-3xl mb-2 font-review font-bold'>{i18next.t(order.name)}</h1>

                  <h4 className='text-lg'> {i18next.t('Regular Price: ')} $ { isNaN(Math.round(order.price[order.size] * 100) / 100) ? '0.00' : (Math.round(order.price[order.size] * 100) / 100).toFixed(2) } </h4>
                </header>
                <section className='flex flex-col font-review py-2 content-center'>
                    <h4>Size:</h4>
                    <div className='flex flex-row'>
                      <Box
                      onClick={() => setOrder({ ...order, size: PRODUCT_SIZE.SMALL })}
                      sx={{ border: '1px solid',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        margin: '5px',
                        width: '40%',
                        borderRadius: '5px',
                        backgroundColor: order.size === PRODUCT_SIZE.SMALL ? 'var(--primary)' : 'white',
                        color: order.size === PRODUCT_SIZE.SMALL ? 'white' : 'black',
                      }}>
                        <img 
                          src={order.size === PRODUCT_SIZE.SMALL ? '/assets/hands/1-white.svg' : '/assets/hands/1.svg'}                          alt="hand"
                          style={{
                            height: '54px',
                            justifySelf: "start"
                          }} 
                        />
                        <p className='font-semibold mr-2'>Small</p>
                        <p className='font-thin'> (${(Math.round(order.price['small'] * 100) / 100).toFixed(2)})</p> 
                      </Box>
                      
                      <Box
                      onClick={() => setOrder({ ...order, size: PRODUCT_SIZE.MEDIUM })}
                      sx={{ border: '1px solid',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        margin: '5px',
                        width: '40%',
                        borderRadius: '5px',
                        backgroundColor: order.size === PRODUCT_SIZE.MEDIUM ? 'var(--primary)' : 'white',
                        color: order.size === PRODUCT_SIZE.MEDIUM ? 'white' : 'black',
                      }}>
                        <img 
                          src={order.size === PRODUCT_SIZE.MEDIUM ? '/assets/hands/2-white.svg' : '/assets/hands/2.svg'}
                          alt="hand"
                          style={{
                            height: '54px',
                            justifySelf: "start"
                          }} 
                        />
                        <p className='font-semibold ml-[-5px] mr-[5px]'>Medium</p>
                        <p className='font-thin'> (${(Math.round(order.price['medium'] * 100) / 100).toFixed(2)})</p> 
                      </Box>

                      <Box
                      onClick={() => setOrder({ ...order, size: PRODUCT_SIZE.LARGE })}
                      sx={{ border: '1px solid',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        margin: '5px',
                        borderRadius: '5px',
                        backgroundColor: order.size === PRODUCT_SIZE.LARGE ? 'var(--primary)' : 'white',
                        color: order.size === PRODUCT_SIZE.LARGE ? 'white' : 'black',
                      }}>
                        <img 
                          src={order.size === PRODUCT_SIZE.LARGE ? '/assets/hands/3-white.svg' : '/assets/hands/3.svg'}                          alt="hand"
                          style={{
                            height: '54px',
                            justifySelf: "start",
                          }} 
                        />
                        <p className='font-semibold mr-3'>Large</p>
                        <p className='font-thin'> (${(Math.round(order.price['large'] * 100) / 100).toFixed(2)})</p> 
                      </Box>
                    </div>
                </section>


                <section className='flex flex-col font-review py-2 content-center'>
                    <h4>Count:</h4>
                    <div className='flex flex-row w-[70%] h-[65px]'>
                      <Button
                      onClick={() => setOrder({ ...order, count: order.count + 1 })}
                      variant="outlined"
                      sx={{ border: '1px solid',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '5px',
                        marginRight: '-5px',
                        width: '75%',
                        borderRadius: '5px',
                        borderTopRightRadius: "0",
                        borderBottomRightRadius: "0",
                        textTransform: 'none',
                        paddingRight: '30px',
                        flex: 1,
                      }}>
                        <div className='flex flex-row font-semibold mr-2 justify-center items-center ml-9'>
                          <p className='flex flex-row text-sm' >
                          <span > + </span>
                          <span> Add </span>
                          </p>
                          <img
                            src="/assets/hands/thumbsup.svg"
                            alt="hand"
                            style={{
                              height: '40px', 
                              objectFit: 'contain', 
                              marginLeft: '-6px',
                              marginTop: '-10px',
                            }}
                          />
                        </div>
                      </Button>

                      <Box
                      sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        width: '5%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '5px',
                        borderRadius: '0px',
                      }}
                      className='bg-primary'
                      >
                        <p className='text-white bg-primary'>{order.count} item(s)</p>
                      </Box>

                      <Button
                        onClick={() => {
                          if (order.count > 0) {
                            setOrder({ ...order, count: order.count - 1 });
                          }
                        }}
                        disabled={order.count < 2}
                        variant="outlined"
                        sx={{
                          border: '1px solid',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flex: 1,
                          margin: '5px',
                          marginLeft: '-5px',
                          width: '75%',
                          borderRadius: '5px',
                          borderTopLeftRadius: "0",
                          borderBottomLeftRadius: "0",
                          textTransform: 'none',
                          paddingRight: '10px',
                        }}
                      >
                        <div className='flex flex-row font-semibold mr-2 justify-center items-center pl-4 pr-2'>
                          <p className='flex flex-row text-sm' >
                          <span > &mdash; </span>
                          <span> Substract </span>
                          </p>
                          <img
                            src="/assets/hands/thumbsdown.svg"
                            alt="hand"
                            style={{
                              height: '40px', 
                              objectFit: 'contain', 
                              marginLeft: '-6px',
                            }}
                          />
                        </div>
                      </Button>

                    </div>
                </section>

                <Divider/>

                <section className='py-2 font-review'>
                  <header>
                    <h2 className='font-review'>Customize: </h2>
                  </header>
                  <div>
                    <Grid container spacing={1}>
                        {customsArray.map((custom, index) => (
                          <Grid item xs={getGridSize(custom.name.length)} key={index}>
                            <Box 
                              onClick={() => handleCustoms(index)}
                              sx={{
                                border: '1px solid',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '5px',
                                height: 'auto',
                                borderRadius: '5px',
                                backgroundColor: custom.value ? 'var(--primary)' : 'white',
                                color: custom.value ? 'white' : 'black',
                              }}
                            > <div className='flex flex-row font-review items-center h-[54px]'>
                                <img 
                                src={custom.value ? `/assets/hands/${custom.hand}-white.svg` : `/assets/hands/${custom.hand}.svg`}
                                alt={custom.hand}
                                style={{
                                  height: '99%',
                                  objectFit: 'contain',
                                  marginRight: '5px',
                                }}
                                />
                                <p className='font-semibold'>{custom.name}</p>
                              </div>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </div>

                </section>
                
                <Divider/>

                <section className='py-2 font-review'>
                  <header>
                    <h2 className='font-review'>Extras: </h2>
                  </header> 

                  <div className='flex flex-col font-review'>
                    <Grid container spacing={1}>
                      {extrasArray.map((extra, index) => (
                        <Grid item xs={getGridSize(extra.name.length)} key={index}>
                          <Box 
                            onClick={() => handleExtras(index)}
                            sx={{
                              border: '1px solid',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '5px',
                              height: 'auto',
                              borderRadius: '5px',
                              backgroundColor: extra.isSelected ? 'var(--primary)' : 'white',
                              color: extra.isSelected ? 'white' : 'black',
                            }}
                            >  
                              <div className='flex flex-row font-review items-center h-[54px]'>
                                <img 
                                  src={extra.isSelected ? `/assets/hands/${extra.hand}-white.svg` : `/assets/hands/${extra.hand}.svg`}
                                  alt={extra.hand}
                                  style={{
                                    height: '95%', 
                                    objectFit: 'contain',
                                    marginRight: '5px',
                                    backgroundColor: extra.isSelected ? 'var(--primary)' : 'white',
                                  }}
                                />
                                <div className='flex flex-col items-center justify-center'>
                                  <p className='font-semibold'>{extra.name}</p> 
                                  <p> (${capitalizeFirstLetter(extra.price)})</p>
                                </div>
                              </div>

                            
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                  </div> 


                </section>
              </Box>

            </Card>

            <Box
            className='py-4'
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
              lineHeight: '1.7',
              '& p': {
                whiteSpace: 'pre', // This respects whitespace characters including spaces
              },
              '& p.regular': {
                color: 'black',
                
              },
              '& p.extras': {
                color: 'gray',
                fontWeight: 90 
              },
              '& p.total': {
                color: 'black',
                fontWeight: 900  
              },
            }}
            >
              <p className="regular">Regular Price:       ${ isNaN( (order.price[order.size] * order.count).toFixed(2) ) ?  '0.00' : (order.price[order.size] * order.count).toFixed(2) }</p> 
              <p className="extras">Extras:                ${ (totalExtras.toFixed(2)) * order.count }</p> 
              <p className="total">Total:         ${ isNaN( ( (order.price[order.size]+totalExtras)*order.count ).toFixed(2) ) ?  '0.00' : ( (order.price[order.size]+totalExtras)*order.count ).toFixed(2) }</p> 
            </Box> 
            <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 1,
            }}
            >
              <Button
                variant="outlined"
                className='flex flex-row justify-center items-center cursor-pointer transition-all'
                onClick={handleConfirmDialogClose}
                sx={{
                  flex: 1,
                  height: 'auto',
                  padding: 0,
                  minHeight: 0,
                  transition: 'transform 0.1.8s ease', // Transición suave
                  '&:hover': {
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                <img 
                  src={'/assets/hands/g.png'}
                  alt="hand"
                  style={{
                    width: '44px',
                    height: '44px',
                    marginRight: '15px',
                  }}
                />
                <p className='font-review'>{i18next.t('Cancel')}</p>
              </Button>

              <Button
                disabled={order.size === PRODUCT_SIZE.NONE} 
                variant="contained"
                className='flex flex-row justify-center items-center cursor-pointer transition-all'
                onClick={addOrderlist}
                sx={{
                  flex: 1,
                  height: 'auto',
                  padding: 0,
                  minHeight: 0,
                  transition: 'transform 0.1.8s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                <img 
                  src={'/assets/hands/y-white.svg'}
                  alt="hand"
                  style={{
                    width: '44px',
                    height: '44px',
                    objectFit: 'contain',
                    marginRight: '15px',
                    padding: '5px',
                  }}
                />
                <p className='font-review'>{i18next.t('Confirm')}</p>
              </Button>

            </Box>
       
          </Box>
        </DialogContent>
    </Dialog>

    </>
  );
}