import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Typography, Divider, CardMedia, CardActions, Button, Grid } from '@mui/material';
import { getCategoryById, setExtrasSelectProcess, setProductSelectProcess  } from '../../store/actions/category.action';
import { speakMP3Promise, stopMP3 } from '../../utils/functions';

const OrderItemCard = (props) => {
  const {
    id,
    categoryId,
    imageUrl,
    count,
    size,
    totalPrice,
    extrasPrice,
    name,
    price,
    hand,
    extras,
    customs,
  } = props.data;

  const{
    setEditId,
    setDeleteId,
    editEnable,
    deleteEnable,
    setReviewOpen,
    setExtrasDialogOpen,
    order,
    setOrder,
    setCustomsArray,
    setExtrasArray,
    handleClickDelete,
  } = props.orderItemCardMethods
  const{ index } = props
  const{ audio } = props

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { category } = useSelector(({ category }) => category);

  const [localProduct, setLocalProduct] = useState({})
  const [localProducts, setLocalProducts] = useState([])


  const fetchData = async () => {
    try {
      const data = await dispatch(getCategoryById(categoryId));
      setLocalProducts(data);
    } catch (error) {
      console.error('ORDER ITEM CARD Error al obtener la categoría:', error);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, []);
  
  function replicateCustomsAndExtras() {
    const updatedExtras = localProduct.extras.map(productExtra => {
        const foundItem = order.extras.find(item => item.name === productExtra.name);
        return {
          ...productExtra,
          isSelected: foundItem ? foundItem.isSelected : false
        };
    });
    setExtrasArray( updatedExtras );
    const updatedCustoms = localProduct.customs.map(productCustom => {
        const foundItem = order.customs.find(item => item.name === productCustom.name);
        return {
          ...productCustom,
          value: foundItem ? foundItem.value : false
        };
    });
    setCustomsArray( updatedCustoms );
  }

  async function handleEditOrDelete() {
    if(editEnable) {
      handleEdit()
    } else if(deleteEnable) {
      handleDelete()
      handleClickDelete() 
    }
  }
  async function handleEdit() {
    fetchData();
    setOrder(props.data)
    const tmp = localProducts.find( item =>  item.name === props.data.name)
    setLocalProduct( tmp )
    setEditId(props.data.id)
  }
  async function handleDelete() {
    setDeleteId(props.data.id)
  }

  useEffect(() => {
    try {
      if(!localProduct || !localProduct.extras || !localProduct.extras  ) return
      replicateCustomsAndExtras();
      setReviewOpen(false)
      dispatch(setProductSelectProcess());
      navigate(`/category/${props.data.categoryId}`);
      setExtrasDialogOpen(true);     
      dispatch(setExtrasSelectProcess());
    } catch (error) {
      console.error('ORDER ITEM CARD 2 --- Error al obtener la categoría:', error);
    }
  }, [localProduct]);

  const getGridSize = (length) => {
    if (length < 13) return 4;
    if (length <= 18) return 6;
    if (length < 22) return 8;    
    if (length >= 22) return 12;                  
  };

  return (
    <Card
    onClick={() => handleEditOrDelete()}
    sx={{
        maxWidth: 700,
        padding: "1rem",
        marginBottom: "1rem",
        border: '1px solid #b0b0b0',
        borderRadius: '5px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.12)',
    }}
    > 
      <main className='flex flex-col w-full justify-between items-center'>

        <section className='flex flex-row  w-full'>
            <img
            src={ (editEnable || deleteEnable) ? `/assets/hands/${index + 1}-orange.svg` : `/assets/hands/${index + 1}.svg`}
            alt={`hand-${index+1}`}
            className='w-[15%] h-[80px] object-contain' 
            />


            <img
            src={imageUrl}
            className='w-[15%] h-[80px] object-contain'
            alt="Food"
            />

            <div className='w-[65%] font-review flex flex-col justify-center items-start ml-5 '>
                <p className='flex flex-row justify-between w-[95%]'> 
                  <div className='flex font-extrabold text-2xl'>{name}</div>
                  <div className='flex font-thin text-2xl'>
                    ${isNaN(totalPrice) ? '0.00' : (Math.round(totalPrice * 100) / 100).toFixed(2)}
                  </div>
                </p>
                <p> Size: {size}</p>
                <p> Count: {count} items</p>
            </div>

        </section>

        <Divider 
        orientation="horizontal" 
        variant="middle" 
        flexItem
        sx={{ margin: '15px 0' }}
        />


        <section className='flex flex-col  w-full'>
            <header>
                <h2 className='font-review text-[#868686] font-bold'>Extras: </h2>
            </header>
            <br />
            <div variant="body2" color="text.secondary">
            <div className='w-full'>
                <Grid container >
                {extras.map( (extra,index) =>  
                        <Grid item lg={getGridSize(extra.name.length)} key={index}>
                          <Box 
                            sx={{
                              border: '1px solid #B0B0B0',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                              margin: '1px',
                              height: 'auto',
                              borderRadius: '10px',
                            }}
                            >  
                              <div className='flex flex-row font-review'>
                                <div className='flex flex-col items-center justify-items-center content-center'>
                                  <p className='text-[#868686] font-semibold'>{extra.name} </p> 
                                </div>
                              </div>
                            
                          </Box>
                        </Grid>
                )}
                </Grid>
            </div>

            </div>
        </section>
      </main>

    </Card>
  );
};

export default OrderItemCard;
