import { Divider, Link } from "@mui/material";

export default function NotFound() {
  return (
    <main className="flex justify-center items-center w-full h-screen">
      <div className="not-found-modal">
        <h1>404</h1>
        <p className="text-gray-500 fade">Oops! The page cannot be found.</p>
        <Divider style={{width:600, margin:"24px 0"}} />
        <Link href={'/'}>Go to Homepage</Link>
      </div>
    </main>
  )
}