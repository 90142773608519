import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Divider, LinearProgress } from '@mui/material';
import CategoryCard from '../components/product/CategoryCard';
import CategoryPagination from '../components/product/CategoryPagination';
import ActionButton from '../components/product/ActionButton';
import { getCategoryList, setCategoryConfirmProcess, setCategorySelectProcess, setProductSelectProcess } from '../store/actions/category.action';
import i18next from 'i18next';
import { speakText, speakMP3Promise,stopMP3 } from '../utils/functions';

export default function Category(props) {
  const {
    categoryDialogOpen,
    setCategoryDialogOpen,
    categoryDialogContent,
    setCategoryDialogContent,
    audio,
    selectItemsUttered,
    setSelectItemsUttered,
    isCam,
    reviewOpen,
    extrasDialogOpen,
  } = props;
  const categoryMsg = async (categoryName)=>{
    const path = `/assets/audio/${categoryName}.mp3`;
    try { stopMP3(audio) } 
    catch (error) { console.error('Error Pausar Category (click):', error) }
    
    await speakMP3Promise(audio, path)
    if(!selectItemsUttered) {
      await speakMP3Promise(audio, '/assets/audio/now-select-ite.mp3')
      setSelectItemsUttered(true)
    }
  }

  const randomPhrase = [ '/assets/audio/select-an-item.mp3',
                         '/assets/audio/want-to-check-y.mp3',
                         '/assets/audio/make-sure-your-.mp3',  
                       ]

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, category } = useSelector(({ category }) => category);

  const { list } = useSelector(({ orderlist }) => orderlist);

  const handleDialogOpen = (data) => {
    setCategoryDialogContent(data);
    setCategoryDialogOpen(true);
    dispatch(setCategoryConfirmProcess());
  }

  const handleDialogClose = () => {
    setCategoryDialogOpen(false);
    dispatch(setCategorySelectProcess());
  }

  const openCategory = () => {
    window.speechSynthesis.cancel()
    let categoryName = categoryDialogContent.name.slice(0, 15).toLowerCase().replace(/\s+/g, '-')
    categoryMsg(categoryName)
    setCategoryDialogOpen(false);
    dispatch(setProductSelectProcess());
    navigate(`/category/${categoryDialogContent.id}`);
  }
  
  useEffect(() => {
    dispatch(getCategoryList())
      .then(() => {
        console.log("Category list fetched successfully");
        dispatch(setCategorySelectProcess());
        })
      .catch(error => {
        console.error("Error fetching category list:", error);
      });
  }, [dispatch]);

  let timer = null;
  useEffect(() => {

    timer = setTimeout(() => {
      const r = isCam ? Math.floor(Math.random() * 3) : Math.floor(Math.random() * 2);
      speakMP3Promise(audio,randomPhrase[r]);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (reviewOpen || extrasDialogOpen) clearTimeout(timer);
  }, [reviewOpen, extrasDialogOpen]);
  

  return (
    <>
      {
        isLoading ? (
          <main className='flex justify-center items-center w-full h-full'>
            <LinearProgress className="w-48" />
          </main>
        ) : category.length > 0 ? (
          <div className=" w-full h-full">
            <main className={'flex flex-col w-full h-full p-8 fade justify-between content-between'}>

                <h1 className='font-bold text-5xl'>{i18next.t('Please select a category to start')}</h1>

                <div className='flex flex-wrap w-full my-8'>
                  {
                    category.map((item) => (
                      <CategoryCard
                        key={item.id}
                        data={item}
                        onClick={handleDialogOpen}
                      />
                    ))
                  }
                </div>


              {/* <Footer /> */}
            </main>


            {/* <CategoryPagination/> */}
          </div>
        ) : (
          <main className='flex justify-center items-center w-full h-full'>
            <h1 className='w-full my-8 text-2xl text-center text-gray-400 fade'>No categories in the database.</h1>
          </main>
        )
      }

      <Dialog 
      open={categoryDialogOpen} 
      onClose={handleDialogClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundImage: "url('/assets/logos/watermark-logo.png')",
          backgroundSize: "80% 80%",  
          backgroundPosition: "right -7% top 4%",  
          backgroundRepeat: "no-repeat",
        },
      }}
      >
        <DialogTitle
        >
          <h1 className='font-primary text-primary text-center text-3xl py-4'>{i18next.t(categoryDialogContent.name)}</h1>
        </DialogTitle>
        <Divider
          variant='middle' 
          sx={{
                width: "60%",
                height: "1px",
                margin: "-5% 20%",
                backgroundColor: "orange",
          }}
        />

        <DialogContent
          
          className='flex flex-col justify-center items-center p-8'
        >
        <p className='text-black text-center text-3xl py-4'>{i18next.t('Your selection:')}</p>

          <img
            alt='preview'
            src={categoryDialogContent.imageUrl}
            width={320}
            height={320}
          />

          <div className='flex flex-col justify-center items-center w-96'>

            <div className='flex justify-between items-center w-full px-16 py-4'>
            <ActionButton

                imageURL={'/assets/hands/no.svg'}
                text={i18next.t('Cancel')}
                className='flex flex-col font-primary justify-center items-center cursor-pointer transition-all hover:translate-y-2'
                onClick={handleDialogClose}
              />
              <ActionButton
                imageURL={'/assets/hands/yes.svg'}
                text={i18next.t('Confirm')}
                className='flex flex-col justify-center items-center cursor-pointer transition-all hover:-translate-y-2'
                onClick={openCategory}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
